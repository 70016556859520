@import "~src/style/variables.less";

form.form {
	.btn.default-list {
		font-size: 1.2em;
		line-height: 1.7em;
		padding: 0 0.5em;
		margin: @gutter-small @gutter-small 0 0;
		position: relative;
		border: none;

		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 100%;
			top: -@gutter-small;
			margin-left: 0.5em;
			box-sizing: content-box;
			border-color: #e0e0e0;
			border-style: solid;
			border-width: @gutter-small 1px @gutter-small 0;
		}
	}

	.decision {
		display: flex;
		flex-direction: column;
		border-bottom: 1px dashed #b9b9b9;
		background: #fdf7e2;
		box-shadow: inset 0 2px 6px -4px black;

		section {
			display: flex;
			flex-wrap: wrap;
		}

		.icon {
			display: flex;

			.fa {
				margin: auto;
				font-size: 2.2em;
				opacity: 0.4;
				padding: 0.3em;
				color: #cd9756;
			}
		}

		.markdown {
			flex: 1;
			font-size: 1.2em;
			line-height: 1.4em;
			padding: 0.9em;
			padding-left: 0;
			margin: 0;

			p {
				white-space: pre-wrap;
			}

			ul, ol {
				list-style-type: disc;
				margin: 0.5em 0 0.5em 1.5em;

				li {
					margin: 0.3em 0;
				}
			}
		}

		.controls {
			padding: 0.3em;
			justify-content: center;
			border-top: 1px dashed #d0d0d0;

			.btn {
				margin: 0.3em;
			}
		}
	}

	.validation-summary {
		font-size: 1em;

		h4 {
			margin-bottom: 0.4em;
		}

		p > span {
			white-space: pre-line;
		}
	}

	section.main .validation-summary {
		border: 1px solid #f3cece;
		border-radius: 2px;
		padding: 0.3em 0.6em;
		margin-bottom: 0.5em;
		align-items: flex-start;
		flex-direction: column;
	}
}
