.ui-dialog {
	position: fixed;

	.ui-dialog-titlebar {
		position: absolute !important;
		background: none;
		border: none;
		top: 14px;
		right: 24px;
		z-index: 1;

		> span {
			display: none;
		}

		> button {
			margin-top: -6px;
			height: 26px;
			width: 25px;
		}
	}

	.ui-dialog-content {
		padding: 0.3em 0.3em 0 !important;
	}
}
