#root-layout.dashboard.chromeless {
	flex-direction: column;

	> #main-content {
		.k-dashboard {
			background: none;
		}

		.k-panel {
			> header {
				.controls {
					display: none;
				}
			}
		}

		.k-panel:not(.form):not(.list):not(.report-query) {
			> main {
				section.main {
					border: 0;
					overflow: unset;
				}
			}
		}

		.grid {
			border: 1px solid gainsboro;

			.actions {
				display: none;
			}

			.grid-buttons {
				display: none;
			}
		}
	}
}

body.modal-frame #root-layout.dashboard.chromeless {
	#main-content {
		padding-top: 10px;
		display: block;

		.k-panel {
			form.form {
				min-height: unset;
				height: 100%;

				header.main {
					border: unset;
					padding: unset;
				}
			}
		}

		.k-dashboard.sticky-header {
			.k-panel-group:first-of-type {
				.k-panel {
					border-bottom: 1px solid #cbcbcb;
					box-shadow: 0 0 7px 0px #00000040;
				}
			}
		}
	}
}
