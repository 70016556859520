.alert {
	padding: 8px 35px 8px 14px;
	margin-bottom: 5px;
	clear: both;

	p {
		margin: 0 0 0.5em;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: normal;
	}

	h6 {
		font-size: 1.05em;
	}

	h5 {
		font-size: 1.1em;
	}

	h4 {
		margin: 0 0 0.5em -0.2em;
		line-height: 20px;
		font-size: 1.2em;
	}

	h3 {
		font-size: 1.3em;
	}

	h2 {
		font-size: 1.4em;
	}

	h1 {
		font-size: 1.5em;
	}

	ol, ul {
		list-style-position: outside;
		margin: 10px 0 10px 25px;

		li {
			margin: 0.3em 0;
		}
	}

	ol {
		list-style-type: decimal;
	}

	ul {
		list-style-type: disc;

		&.dropdown-menu {
			list-style-type: none;
			margin: 2px 0 0;

			li {
				margin: 0;
			}
		}
	}
}

.alert.error {
	color: #864c4b;
}
