@import "~src/style/mixins.less";

.customize-message {
	background-color: #fcf8e3;
	color: #886d3e;
	text-align: center;
	padding: 5px;
	position: fixed;
	top: 0;
	left: 50%;
	width: 360px;
	z-index: 10000;
	margin-left: -180px;
	border-radius: 0 0 5px 5px;
	opacity: 0.7;
	.transition-all(@time: 0.2s);
	border: 1px solid #CECECE;
	border-top: 0;
	cursor: default;

	&:hover {
		opacity: 1;
	}
}
