.gantt-container {
    .k-gantt {
        border: 0;
    }
}

.k-gantt {
    &, .k-treelist, .k-gantt-treelist .k-grid-content {
        position: initial;
    }

    .k-toolbar button {
        padding: 4px;
    }

    .k-treelist tr {
        td {
            vertical-align: middle;
        }

        &.k-alt {
            background-color: #f0f0f0;
        }

        &:hover {
            background-color: #e0e0e0;
        }

        th .k-link .k-column-title {
            cursor: pointer;
        }
    }

    .k-gantt-timeline {
        table {
            min-width: 0;

            .k-task-wrap {
                height: auto !important;
                margin-top: 0;

                &.k-summary-wrap {
                    margin-top: 13px;
                }

                .k-task-single {
                    border: none;
                    background: none;
                    margin-left: 1px;
                }

                .k-task-content {
                    .k-task-template {
                        padding: 0;

                        .task-details {
                            border-radius: 5px;
                            border-width: 1px;
                            border-style: solid;

                            &.background-default {
                                @background-color: #37a;
                                background-color:  @background-color;
                                border-color: darken(@background-color, 30%);

                                .k-task-complete {
                                    opacity: 0.2;
                                }
                            }

                            .task-text {
                                position: relative;
                                padding: 0 2px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                line-height: 25px;
                                z-index: 2;
                            }

                            .task-progress {
                                position: absolute;
                                background-color: #000;
                                border-radius: 5px;
                                height: 100%;
                                top: 0;
                                left: 0;
                                opacity: 0.1;
                                z-index: 1;
                            }
                        }
                    }

                    .k-resize-handle {
                        display: none;
                    }

                    &.can-move-start .k-resize-w {
                        display: block;
                    }

                    &.can-move-end .k-resize-e {
                        display: block;
                    }

                    &.is-locked {
                        opacity: 0.7;
                    }

                    &.can-move {
                        cursor: move;
                    }

                    &.is-complete .task-text::before {
                        content: '\2714'
                    }

                    &.has-changes {
                        font-style: italic;
                    }

                    &.is-removed {
                        background-color: #f99;
                        opacity: 0.5;

                        .task-text {
                            text-decoration: line-through;
                        }

                        .k-resize-handle {
                            display: none;
                        }
                    }
                }
            }
        }

        .k-tooltip .k-task-details {
            .task-status {
                font-size: 1.1em;
                margin-bottom: 0.4em;

                .task-status-color {
                    display: inline-block;
                    width: 0.8em;
                    height: 0.8em;
                    border-radius: 0.5em;
                }
            }

            .k-task-pct {
                margin: 0.1em 0;
            }
        }
    }

    table tbody tr td .removed {
        text-decoration: line-through;
        opacity: 0.4;
    }

    .k-gantt-content .k-gantt-timeline .k-grid-header-wrap {
        table tr:nth-child(2) {
            td {
                font-size: 10px;
                line-height: 20px;
            }
        }
    }

    .k-task-single,
    .k-task-summary,
    .k-task-summary-complete {
        min-width: 10px !important;
    }

    .k-task-milestone {
        width: 8px;
        height: 8px;
    }
}
