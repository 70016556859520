.k-panel.list, .k-panel.report-query {
	form {
		height: 100%;
		min-height: 0;
	}
}

.k-panel .grid, .sub-tabs .grid {
	&.with-filter {
		.alert.empty {
			border: none;
		}
	}
}

.k-panel.auto-height .grid, .sub-tabs .grid {
	max-height: 75vh;
}