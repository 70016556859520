@import "~src/style/variables.less";

form.form {
	.tab-content {
		border: 1px solid #ddd;
		border-top: 0;
		padding: 0.5em;
		min-height: 10em;

		.customize-position-fix {
			position: absolute;
			margin: 1px 2px;
			z-index: 3;

			.customize-list-menu {
				margin: 2px;
			}
		}

		.customize-list-menu {
			margin: 4px;
			display: inline-block;

			button {
				padding: 2px 4px;
			}

			> .btn > .caret {
				margin-left: 4px;
			}

			+ .alert {
				border-top: 1px solid gainsboro;
			}
		}

		.alert > .customize-list-menu {
			margin: 0;
			color: #333;

			ul {
				list-style-type: none;
				list-style-position: inside;
				margin: 0;
			}
		}
	}

	.tab-pane {
		.alert {
			margin-bottom: 0;
		}

		> .row {
			margin: 0;
		}

		> fieldset.first-visible.no-legend {
			margin-top: .7em;

			> hr {
				display: none;
			}
		}
	}

	section.main {
		flex: 1;
		overflow: auto;
		border-top: 1px solid gainsboro;

		> .nav-tabs {
			padding-left: 0.8em;
			padding-top: 0.6em;
			background-color: #fdfcfc;
			box-shadow: inset 2px -2px 8px -7px black;

			.fa {
				font-size: 1.2em;
				vertical-align: middle;
				padding-right: 0.2em;
			}
		}

		> .tab-content {
			border: none;
			padding: @gutter-large @gutter-large 3em;

			.attachments-tab {
				overflow: hidden;

				.no-attachments {
					display: block;
					margin: 1em;
					opacity: 0.7;
				}

				.fileset {
					margin: 1em 0;
					width: 100%;

					.table {
						margin: 1em 0;

						th, td {
							border: 1px solid #dadada;
						}

						th {
							border-width: 1px;
							font-size: 90%;
							text-transform: uppercase;
							background-color: #f0f0f0;
							color: #757575;
							font-weight: bold;
						}

						.controls {
							padding: 0.4em;
							background-color: #f0f0f0;
						}

						tr.upload {
							td.text {
								border-right: 0;
							}

							td.progress-cell {
								border-left: 0;
							}
						}
					}
				}
			}
		}

		> .body.tabbed {
			padding: 0;
			border-top: 1px solid #e8e8e8;
		}
	}
}

@media (max-width: @screen-xs-min) {
	.form section.main > .tab-content {
		padding: 2px;

		fieldset {
			margin: 2px;
		}
	}
}
