@import "~src/style/variables.less";

.dashboard-container {
	flex: 1;
	background-color: #ebeff2;
	box-shadow: inset 0px 0px 6px -2px #3e3e3e;
	border-top: 1px solid #a2a2a2;
	padding: @gutter-large;
	overflow: auto;

	.k-panel {
		margin: @gutter-large;
		background-color: white;
		border-radius: 3px;
		border: 1px solid #cbcbcb;
		box-shadow: 0 0 4px 0px #0000002e;
	}
}
