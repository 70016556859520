.input-append {
	.input-group-addon {
		height: 16px;
		line-height: 16px;
	}

	.popover .popover-content {
		font-size: 12px;
	}
}
