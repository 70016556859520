@import "~src/style/variables.less";
@import "~src/style/mixins.less";

@top-bar-color: #fff;

#top-bar {
	display: flex;
	flex-shrink: 0;
	color: @top-bar-color;
	background-color: #464c5b;

	.top-controls {
		flex: 1;
		display: flex;
		flex-direction: row;
		min-width: 0;
		background: linear-gradient(to right, #047ab1 45%, @branding-color 95%);
		background-color: @branding-color;
		border-top: 1px solid #3c3c3c;
		border-bottom: 1px solid #505050;
	}

	.server-name {
		align-self: center;
		border: 1px dashed #cecece;
		border-radius: 3px;
		background: #31291f;
		padding: 0.3em 0.5em;
		font-size: 10px;
		opacity: 0.6;
		margin: 0.3em;
		color: @top-bar-color;
		white-space: nowrap;

		.fa, .glyphicon {
			padding: 0 0.3em;
			font-size: 0.9em;
		}
	}

	.top-list {
		display: flex;
		align-items: center;
		color: inherit;

		.fa, .glyphicon {
			color: inherit;
			font-size: 16px;
			vertical-align: text-bottom;
		}

		.user-avatar.customizing {
			background: #f0ad4e;
			border: 1px solid rgba(255, 255, 255, 0.44);
			border-radius: 3px;
			text-shadow: 0 0 3px rgba(0, 0, 0, 0.37);

			&:focus {
				outline: 5px auto -webkit-focus-ring-color;
				outline-offset: -2px;
			}
		}

		> li {
			> div {
				opacity: 0.9;

				> a {
					display: block;
					cursor: pointer;
					text-align: center;
					border-radius: 2px;
					margin: 0 2px 0;
					padding: 7px 7px;
					color: inherit;
					border: 1px solid transparent;
					text-decoration: none;
				}
			}

			&:hover {
				> div {
					opacity: 1;

					> a {
						.box-shadow(@blur: 30px, @color:rgba(255, 255, 255, 0.2));
						border: 1px solid fade(@top-bar-color, 16%);
					}
				}
			}

			&.selected {
				> div {
					opacity: 1;

					> a {
						background: rgba(255, 255, 255, 0.2);
						.box-shadow(@blur: 30px, @color:rgba(255, 255, 255, 0.2));
						border: 1px solid fade(@top-bar-color, 16%);
						text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
					}
				}
			}
		}
	}

	.top-menu {
		padding: 0.2em 0;
		flex: 1;
		overflow: auto;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: #ffffffa3;
		}

		&::-webkit-scrollbar-track {
			background: #0000003d;
		}

		li {
			span {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				min-width: 5em;
				overflow: hidden;
			}
		}
	}

	.options {
		justify-content: flex-end;

		> ul, .server-name {
			flex: 0;
		}

		> ul {
			:focus {
				outline: 5px auto -webkit-focus-ring-color;
				outline-offset: -2px;
			}

			> li.settings, > li.applications, > li.notifications {
				> div.open {
					opacity: 1;
				}
			}

			> li.settings {
				.dropdown {
					a, span {
						white-space: nowrap;
					}

					.user-name {
						cursor: default;
						display: block;
						padding: 3px 20px;
						font-weight: normal;
						line-height: 16px;
						color: #868686;
					}
				}
			}

			> li.applications {
				> div.application {
					> a {
						color: inherit;
						font-size: 11pt;
						line-height: 20px;
						text-transform: uppercase;
						letter-spacing: 0.1em;
						text-shadow: 1px 1px 3px rgba(0,0,0,0.4);
						margin-bottom: 0;
						white-space: nowrap;

						&:focus, &:hover {
							color: inherit;
							text-decoration: none;
						}
					}

					> ul {
						min-width: 100%;
					}
				}
			}
		}
	}
}

.top-bar-label {
	display: block;
	font-weight: normal;
	color: @top-bar-color;
	text-transform: uppercase;
	font-family: Helvetica,Tahoma,sans-serif;
	cursor: pointer;
	margin-top: 4px;
	line-height: 12px;

	.caret {
		margin-top: 4px;
	}

	&:hover {
		color: #fff;
	}
}

.dropdown-menu {
	li.create {
		opacity: 0.5;
		margin-top: 2px;
		padding-top: 1px;
		border-top: 1px dashed #ccc;
		font-style: italic;
		position: relative;

		> a {
			padding-right: 30px;

			&:after {
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				content: "\f067";
				position: absolute;
				right: 10px;
			}
		}

		&:hover {
			opacity: 1;
		}
	}
}

@media screen and (max-width: @screen-sm-min) {
	.top-menu li a span {
		display: none;
	}
}
