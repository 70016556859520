@import "~src/style/variables.less";

.form-control.k-input {
	padding: 0;
	width: 100%;
	height: auto;
	line-height: @line-height-base;

	&.k-hover,
	&.k-focus {
		background-color: transparent;
		background-image: none;
		box-shadow: none;
	}

	&.k-hover {
		border-color: #d0d0d0;
	}

	&.k-focus {
		border-color: @focused-border-color;
	}

	input.form-control.k-input-inner {
		height: @input-height-base;
		padding: @padding-base-vertical @padding-base-horizontal;
		font-size: @font-size-base;
		line-height: @line-height-base;
		color: @input-color;
	}

	.k-input-button.k-button {
		height: @input-height-base;
	}

	&.k-state-disabled {
		padding: 0;
		opacity: initial;

		.k-input {
			border-radius: 3px;
		}

		.k-select {
			display: none;
		}
	}
}
