#reportViewer {
	position: absolute;
	left: 5px;
	right: 5px;
	top: 5px;
	bottom: 5px;
	font-family: 'segoe ui', 'ms sans serif';
	overflow: hidden;
}

.report {
	.report-header {
		padding: 2px 0 10px;
	}

	.report-footer {
		padding: 10px 0;
	}

	.report-table {
		border-top: solid 1px gainsboro;

		thead {
			th {
				vertical-align: auto;

				.glyphicon {
					&.sort-ascending, &.sort-descending {
						display: none;
					}
				}

				&.sort-ascending {
					.glyphicon.sort-ascending {
						display: inline;
					}
				}

				&.sort-descending {
					.glyphicon.sort-descending {
						display: inline;
					}
				}

				&.column-group-header {
					text-align: center;
				}
			}
		}

		td, th {
			text-align: left;
			vertical-align: middle;

			&.boolean > .report-value {
				justify-content: center;
			}

			&.integer, &.float {
				> .report-value {
					justify-content: flex-end;
				}
			}

			&.image-url {
				img {
					max-width: 260px;
					max-height: 180px;
				}
			}

			.report-value {
				display: flex;
				flex: 1;

				.display {
					padding: 0.4em 0 0.2em;
				}
			}
		}

		tr[class*="group-footer"] td {
			font-weight: bold;
		}

		tr.report-total, tr.report-total-row {
			td {
				font-weight: 900;
				font-size: 1.1em;
			}
		}

		tr.report-total {
			td {
				border-bottom: double 3px gainsboro;
			}
		}
	}
}
