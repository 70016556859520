#main-menu.filtered {
	.search {
		.clear {
			display: block;
		}
	}

	section.items {
		> ul {
			li {
				display: none;

				.item {
					opacity: 0.3;
				}
			}
		}

		.filter-visible {
			display: block;

			&.parent {
				> .item {
					.tree-expand {
						display: flex;
					}

					.tree-collapse {
						display: none;
					}
				}

				&.filter-expanded {
					> .item {
						.tree-expand {
							display: none;
						}

						.tree-collapse {
							display: flex;
						}
					}

					> ul {
						display: block;
					}
				}
			}
		}

		.filter-hit {
			> .item {
				opacity: 1;
				border-left-width: 0.5em;
				border-left-style: solid;
			}
		}

		li.expanded {
			> .item .tree-collapse {
				display: none;
			}

			> ul {
				display: none;
			}
		}
	}

}

#main-menu.filtered.without-matches {
	.empty {
		display: block;
	}
}
