.form-group.error input,
.form-group.error select,
.form-group.error textarea,
.form-group.error .select2-container > a,
.form-group.error .k-picker-wrap,
.form-group.error .k-picker-wrap.k-state-hover,
.form-group.error .k-picker-wrap.k-state-focused,
.form-group.error .select2-drop-active,
.select2-drop.error {
	border-color: #b94a48;
}

.form-horizontal {
	.control-label {
		text-align: left;
		margin-bottom: 0;
		padding-top: 5px;
	}

	.form-group {
		margin-top: 0;
		margin-bottom: 5px;
	}
}

@media (min-width: @screen-md-min) {
	.form-horizontal .control-label {
		text-align: right;
	}
}
