#main-menu.light {
	.scrollbars() {
		&::-webkit-scrollbar-track {
			background: #e6e6e6;
		}

		&::-webkit-scrollbar-thumb {
			background: #d4d4d4;
		}

		&::-webkit-scrollbar-thumb:window-inactive {
			background: #d4d4d4;
		}

		&::-webkit-scrollbar-corner {
			background: #e6e6e6;
		}
	}

	@icon-color: #505050;
	@panel-color: #fdfcfc;

	background-color: @panel-color;
	box-shadow: 1px 0px 7px 0px rgba(0, 0, 0, 0.48);

	a {
		color: #3e3e3e;
	}

	#toggle-menu {
		color: @icon-color;

		&.active {
			color: #888787;
			background-color: #e9eaec;
			box-shadow: inset 0 0 3px 0px rgba(0, 0, 0, 0.54);
			border: 1px solid #ffffff;
		}
	}

	.location {
		border-color: rgba(0, 0, 0, 0.27);
		border-bottom-color: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.34);
		background: rgba(0, 0, 0, 0.09);
		box-shadow: inset 0 0 7px -4px black;

		a {
			border-color:  #757575;
			background-color: #cfd3da;
		}

		a:hover {
			background-color: #dadde2;
		}

		a.active {
			background-color: #0394dd;
			color: #fff;
		}
	}

	.search {
		background-color: @panel-color;
		border-top: 1px solid rgba(0, 0, 0, 0.28);
		border-bottom: 1px solid #d2d2d2;

		input {
			background-color: rgba(255, 255, 255, 0.85);
			border-color: #c3c3c3;
		}
	}

	.fa {
		color: @icon-color;
	}

	.empty {
		color: rgba(0, 0, 0, 0.36);
	}

	section.items {
		.scrollbars();

		ul.level-1 {
			.scrollbars();
		}

		ul.level-2 {
			.scrollbars();
		}

		li.recent {
			> .item {
				.item-label {
					color: rgba(0, 0, 0, 0.4);
				}
			}
		}

		li:not(.recent) {
			&:not(.active-flyout) {
				.item:hover {
					background-color: rgba(255, 194, 107, 0.09);
				}
			}

			&.expanded {
				&.active:not(.parent):not(.filter-hit) {
					background-color: #a3e0ff;
				}
			}
		}
	}

	.customize {
		.fa {
			color: white;
			opacity: 0.9;
		}

		.edit {
			background-color: #f0ad4e;
		}

		.add {
			background-color: #5cb85c;
		}
	}
}

#main-menu.light.collapsed {
	@header-color: #fff9e5;

	.flyout-header {
		border: 1px solid #ccc;
		background: @header-color;
	}

	.flyout-header:not(.search) {
		border-left: 1px dashed rgba(130, 130, 130, 0.3);
	}

	.flyout-header.search {
		z-index: 3;
		box-shadow: -1px 3px 6px -3px rgba(0, 0, 0, 0.4);
		border-bottom: 1px solid #b7b7b7;

		input {
			outline-color: #ffdc8e;
		}
	}

	.flyout-list {
		background-color: white;
		border: 1px solid #bababa;
		border-top: none;

		&.flyout-menu {
			box-shadow: 0px 4px 17px -2px rgba(0, 0, 0, 0.45);
		}

		&.flyout-drawer {
			box-shadow: 4px 8px 17px 0px rgba(0, 0, 0, 0.3);
		}
	}

	.active-flyout {
		background: @header-color;
		box-shadow: inset 10px 0px 4px 0px rgba(0, 0, 0, 0.49);

		&.recent {
			.flyout-header {
				border-left: none;
			}
		}

		&:hover {
			background: @header-color;
		}
	}
}

#main-menu.light:not(.filtered) {
	section.items {
		li:not(.recent) {
			&.expanded {
				&.parent {
					ul:not(.flyout-list) {
						background-color: rgba(127, 127, 127, 0.05);
						border-top: 1px solid rgba(0, 0, 0, 0.14);
						box-shadow: inset 0px 3px 9px -7px black;
					}
				}
			}
		}
	}
}

#main-menu.light.filtered {
	.filter-hit {
		background-color: #fff6d9;

		> .item {
			border-left-color: #efa53e;
		}
	}
}

#main-menu.light.filtered.without-matches {
	.search {
		background-color: #eddede;

		input {
			outline-color: #f9bebe;
		}
	}
}
