@import "~src/style/variables.less";
@import "~src/style/mixins.less";

#main-menu {
	.scrollbars() {
		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
	}

	z-index: 2;
	width: @menu-width;
	min-width: @menu-width;
	display: flex;
	flex-direction: column;
	transition: all 80ms;

	#toggle-menu {
		padding: 7px 8px;
		font-size: 16px;
		border: 1px solid transparent;
		border-radius: 2px;
		margin: 0 0.4em;
		outline: none;
	}

	.branding {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: space-between;

		.logo {
			overflow: hidden;
			height: @top-bar-height;
			width: 200px;
			display: flex;
			align-items: center;

			> img {
				max-height: @top-bar-height;
				max-width: 200px;
			}

			&:hover, &:focus {
				text-decoration: none;
			}
		}
	}

	&.with-location {
		& section.items > ul > li.location-hidden:not(.recent) {
			display: none;
		}
	}

	.location {
		border-style: solid;
		border-width: 1px 0;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		padding: 0.3em 0.3em;

		> span {
			flex: 1;
		}

		a {
			padding: 0.2em 0.5em;
			border-style: solid;
			border-width: 1px 0;
			transition-duration: 0.15s;
			transition-property: color;
			text-decoration: none;
			cursor: default;
		}

		@radius: 3px;

		a:first-of-type {
			border-left-width: 1px;
			border-radius: @radius 0 0 @radius;
		}

		a:last-of-type {
			border-right-width: 1px;
			border-radius: 0 @radius @radius 0;
		}
	}

	.search {
		display: flex;
		flex-shrink: 0;
		position: relative;
		padding: 0.7em 0.9em;
		border-bottom: 1px solid transparent;

		input {
			border-radius: 4px;
			width: 100%;
			border: 1px solid;
			padding: 0.4em;
			padding-right: 2.5em;
		}

		.clear {
			display: none;
			position: absolute;
			right: 1.2em;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 6px;
			padding: 0.1em 0.4em;
			border: none;
			background: none;
			opacity: 0.8;
		}

		#toggle-search {
			display: none;
		}
	}

	.empty {
		text-align: center;
		display: none;
		padding: 1em;
	}

	section.items {
		overflow: auto;
		.scrollbars();

		li {
			.item {
				display: flex;
				padding-right: 1em;

				.icon {
					align-self: center;
					padding-left: 1em;
				}

				.tree {
					display: none;
					align-items: center;
					outline: none;
				}

				.item-label {
					cursor: pointer;
					flex: 1;
					padding: 0.7em;

					&:hover {
						text-decoration: none;
					}
				}
			}

			.interactive i.glyphicon {
				margin-top: 3px;
			}
		}

		li.parent {
			> .item .tree-expand {
				display: flex;
			}

			&.expanded {
				> .item {
					.tree-expand {
						display: none;
					}

					.tree-collapse {
						display: flex;
					}
				}

				> ul {
					display: block;
				}
			}
		}

		ul.level-1 {
			.scrollbars();

			> li {
				> .item {
					.item-label {
						font-size: 1.25em;
					}
				}

				ul {
					display: none;
				}
			}

			.recent {
				border-bottom: 2px groove transparent;

				.icon, .tree {
					padding-left: 1em;
					opacity: .5;
				}

				> .item {
					.item-label {
						text-transform: uppercase;
						font-weight: bold;
						font-size: 1em;
						padding-left: 0.4em;
					}
				}

				ul {
					padding-bottom: 1em;

					.item-label {
						padding: 0.5em;
						font-size: 1em !important;
					}
				}

				.customize {
					display: none;
				}
			}
		}

		ul.level-2 {
			.scrollbars();

			> li {
				> .item {
					.icon {
						padding-left: 2em;
					}

					.item-label {
						font-size: 1.2em;
					}
				}
			}
		}

		ul.level-3 {
			.item {
				.icon {
					padding-left: 3em;
				}

				.item-label {
					font-size: 1em;
				}
			}
		}

		ul.level-4 {
			.item {
				.icon {
					padding-left: 4em;
				}
			}
		}

		ul.level-5 {
			.item {
				.icon {
					padding-left: 5em;
				}
			}
		}
	}

	.customize {
		display: flex;
		align-items: center;
		padding-left: 1em;

		.fa {
			margin: 0.3em;
			padding: 6px;
			border-radius: 3px;
			text-decoration: none;
			opacity: 0.7;

			&:hover {
				opacity: 1;
			}
		}
	}

	.create-menu {
		padding: 1em;
		justify-content: flex-end
	}
}
