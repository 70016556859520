@import "~src/style/variables.less";

.modal.popup-window {
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 !important;

	> .modal-dialog {
		width: 60%;
		height: 70%;
		margin: 0;
		display: flex;

		> .modal-content {
			@radius: 6px;

			flex: 1;
			display: flex;
			flex-direction: column;

			> header.main {
				border-radius: @radius @radius 0 0;
				border-bottom: 1px solid #d1d1d1;
				height: @modal-close-size + 1px;

				label {
					font-size: 1.3em;
					padding: 0 0.6em;
					opacity: 0.8;
				}
			}

			> section {
				flex: 1;
				min-height: 0;

				> iframe {
					height: 100%;
					border-radius: @radius;
				}
			}

			> .close-control {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: 0;
				border-left: 1px solid #d6d6d6;
				background: #fff;
				border-top-right-radius: @radius;
				height: @modal-close-size + 1px;
				width: @modal-close-size;
			}
		}
	}

	&.with-title {
		> .modal-dialog {
			> .modal-content {
				> .close-control {
					height: @modal-close-size;
					background: transparent;
					border-color: #e4e4e4;
				}
			}
		}
	}

	@offset: 20px;

	&.offset-1 > .modal-dialog {
		margin-left: @offset;
		margin-top: @offset;
	}

	&.offset-2 > .modal-dialog {
		margin-left: @offset * 2;
		margin-top: @offset * 2;
	}

	&.offset-3 > .modal-dialog {
		margin-left: @offset * 3;
		margin-top: @offset * 3;
	}

	&.offset-4 > .modal-dialog {
		margin-left: @offset * 4;
		margin-top: @offset * 4;
	}
}

.modal-content .validation-summary {
	white-space: normal;
}

body.modal-open {
	padding: 0 !important;
}

body.modal-frame {
	background: transparent;
	overflow-y: auto;
	overflow-x: auto;
}

.modal-frame {
	form.form {
		height: 100vh;
		max-height: 100vh;
		min-height: 100vh;
	}

	.grid {
		border: 0;

		.select-panel {
			border: 0;
			border-bottom: 1px solid #d0d0d0;
		}
	}

	header.main {
		//this must appear before other sections in the layout
		order: -1;
		border-bottom: 1px solid gainsboro;
		min-height: @modal-close-size + 1px;
		padding-right: @modal-close-size + 5px;

		label {
			padding-left: 0.5em;
		}

		aside.controls {
			.btn.form-cancel {
				display: none;
			}
		}

		.btn.default-list {
			display: none;
		}
	}

	header.alert {
		border-bottom-color: #cfcfcf;
	}

	section.main, form.form > section.main {
		border-top: none;
	}

	footer.main {
		display: none;
	}
}

.modal-frame.with-title {
	header.main {
		aside.controls {
			margin-right: 0.3em;
		}
	}
}
