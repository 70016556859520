.popover {
	.popover-title {
		padding: 0.5em 0.7em;
		line-height: normal;
		font-weight: normal;
		font-size: 1.1em;
	}

	.popover-content {
		padding: 0.5em 0.8em;
		max-height: 10em;
		overflow: auto;
	}
}
