.dashboard-form {
	flex: 1;
	display: flex;
	overflow: hidden;

	.k-dashboard {
		flex: 1;

		.k-panel-group {
			flex: 1;
			overflow: hidden;

			.k-panel {
				> header label {
					opacity: 0.7;
				}

				&.form {
					> header {
						display: none;
					}

					> main {
						flex: 1;
						background: white;
						display: flex;
						overflow: auto;
					}
				}

				&.tree {
					position: relative;
					border-right: 1px solid #c4c4c4;

					> header {
						display: none;
					}

					> main {
						flex: 1;
						display: flex;
						padding: 0;
					}

					.tree-container {
						overflow: auto;

						> aside.controls {
							padding: 0.3em;
							border-bottom: 1px solid #c7c7c7;
							background: #fdfcfc;

							button {
								padding: .4em;
							}
						}

						.tree {
							padding: 10px;
							box-shadow: inset 0 0 15px -6px black;

							.k-in .heading {
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}
}
