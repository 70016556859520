.two-factor {
    .qr-code {
        img {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 160px;
        }

        .manual-code {
            text-align: center;
            font-style: italic;
            font-weight: bolder;
        }    
    }
}

#start-main {
    header.main {
        display: none;
    }
}