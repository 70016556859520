form.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 20em;
  min-width: 20em;
  position: relative;
  visibility: hidden;
}
form.form > section.main {
  display: none;
}
form.form .color-swatch {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-bottom: 0.8em;
  margin-right: 0.5em;
  border: thin solid black;
}
form.form > header {
  flex-shrink: 0;
}
form.form .button-group input {
  line-height: 20px;
  width: 100%;
}
form.form .help-inline {
  margin-top: 6px;
  margin-right: 6px;
}
form.form .expression-error {
  color: red;
}
form.form .form-buttons {
  padding-bottom: 10px;
  text-align: right;
}
form.form .form-footer {
  text-align: right;
}
form.form.form-horizontal .control-label:after {
  content: ':';
  margin-left: 0.1em;
  bottom: 0;
  right: 0;
}
form.form.form-horizontal input[type="checkbox"] {
  margin-top: 6px;
}
form.form.form-horizontal input[type="radio"] {
  margin-top: 1px;
}
form.form.form-horizontal div.checkbox input[type="checkbox"] {
  margin-top: 2px;
}
@media (min-width: 992px) {
  form.form.form-horizontal .field-label {
    text-align: right;
  }
}
form.form .form-control-static {
  min-height: 16px;
}
form.form .form-control-static:empty:after {
  content: 'n/a';
  color: #dedede;
}
form.form .form-control:focus {
  background-color: #f0f7fd;
  box-shadow: none !important;
}
form.form .select2-container-active .select2-choice,
form.form .select2-container-active .select2-choices {
  background-color: #f0f7fd;
}
form.form .select2-container-active.select2-dropdown-open .select2-choice,
form.form .select2-container-active.select2-dropdown-open .select2-choices {
  background-color: white;
}
form.form .form-control-static.email:empty:after {
  content: '\200c';
}
form.form .control-label {
  font-size: 11px;
  opacity: 0.7;
  overflow: hidden;
  text-overflow: ellipsis;
}
form.form .required .control-label {
  font-weight: bold;
  opacity: 0.9;
}
form.form .field-label {
  padding-right: 0;
}
form.form .control-label.checkbox {
  display: inline-block;
}
form.form fieldset .alert.fieldset-description {
  margin-bottom: 10px;
}
form.form .field.edit .controls {
  white-space: nowrap;
}
form.form em.required {
  color: red;
  display: none;
}
form.form .field.required em.required {
  display: inline;
}
form.form.form-vertical .checkbox input[type="checkbox"] {
  margin-top: 2px;
}
form.form pre.display {
  border: 0;
  background: transparent;
  font: inherit;
  white-space: pre-wrap;
  word-break: break-word;
}
form.form .display {
  padding: 5px 0 0;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}
form.form .display.link-display {
  display: flex;
  flex: 1;
  padding: 0;
}
form.form .display.multi-select {
  max-height: 13em;
  padding-right: 1em;
  overflow: auto;
}
form.form .display.multi-select > a {
  display: block;
}
form.form .info-link {
  height: 1.8em;
  width: 1.8em;
  margin: 0.1em 0 0.1em 0.4em;
  padding: 0 0.7em 0 0.5em;
}
form.form .edit-link {
  height: 1.8em;
  width: 2em;
  margin: 0.1em 0 0 0.4em;
  padding: 0;
}
form.form .link-aside {
  display: inline-block;
  margin-left: 0.3em;
  padding: 0.2em 0.5em;
}
form.form .null-value {
  color: #dedede;
}
form.form .alert .btn-sm + strong {
  margin-left: 1em;
}
form.form .display.removed {
  text-decoration: line-through;
  opacity: 0.4;
}
form.form .has-fieldset .sub-tabs {
  margin-top: 20px;
}
form.form .single-sub-tab .sub-tabs .nav-tabs {
  display: none;
}
form.form .single-sub-tab .sub-tabs .tab-content {
  border: none;
  padding: 0;
}
form.form .update-mask,
form.form .save-mask {
  background: white;
  position: absolute;
  opacity: 0.6;
  z-index: 1000;
  background-image: url("../../../plugins/select2/spinner.gif");
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
form.form .form-group {
  position: relative;
}
form.form .box {
  display: table;
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
}
form.form .box .select2-custom-container {
  display: flex;
  align-items: flex-start;
}
form.form .box .select2-custom-container .select2-dropdown-container {
  width: 100%;
  min-width: 50px;
}
form.form .box .select2-custom-container .select2-dropdown-container .select2-container[style] {
  display: block;
  width: 100% !important;
}
form.form .box .select2-custom-container .select2-button-container {
  margin-left: 0.3em;
}
form.form .box .select2-custom-container .select2-button-container i {
  min-width: 10px;
}
form.form .box .select2-custom-container ul.select2-choices {
  overflow: auto;
  max-height: 200px;
}
form.form .box > a {
  margin-left: 5px;
}
form.form .paginator > ul {
  vertical-align: middle;
}
form.form .paginator > .alert {
  padding: 5px 20px 8px 15px;
  margin-bottom: 0;
}
form.form select option.option-group {
  color: black;
  font-size: 1.1em;
}
form.form .sensitive .sensitive-content {
  display: none;
}
form.form .sensitive.show-content .btn {
  margin-bottom: 5px;
}
form.form .sensitive.show-content .sensitive-content {
  display: block;
}
form.form .sensitive.show-content .default-content {
  display: none;
}
form.form .col-width-auto {
  width: auto;
}
form.form .toggle-control {
  cursor: pointer;
}
form.form div span.flag-option:not(:last-of-type):after {
  content: ", ";
}
form.form.application-directory section.main {
  padding: 0.5em;
}
form.form.application-directory section.main .k-widget.k-treeview .k-in.k-state-selected {
  background: #f9f0c4;
}
form.form.application-directory section.main .k-widget.k-treeview .k-in.k-state-selected .heading {
  color: #000000c4;
  font-weight: bold;
}
form.form.application-directory section.main .k-widget.k-treeview .k-in span.fa {
  margin-right: 0;
  margin-left: 7px;
  vertical-align: middle;
}
textarea.form-control {
  resize: vertical;
}
.text-left > input.form-control {
  text-align: left;
}
.text-right > input.form-control {
  text-align: right;
}
span.status {
  height: 1.4em;
  width: 1.4em;
  margin-right: 0.1em;
  border-radius: 50%;
  display: inline-block;
}
.background-blue {
  background: #6b9fb9;
  border-color: #3d6a81;
  color: #fff;
}
.background-red {
  background: #f77e7e;
  border-color: #f11e1e;
  color: #fff;
}
.background-yellow {
  background: #f9dd54;
  border-color: #dfbb08;
  color: #7c7c7c;
}
.background-green {
  background: #b8d09a;
  border-color: #87b054;
  color: #7c7c7c;
}
.background-grey {
  background: #8f8f8f;
  border-color: #5c5c5c;
  color: #fff;
}
.background-purple {
  background: #b18eb1;
  border-color: #805980;
  color: #fff;
}
form.form fieldset {
  margin: 0 0.5em;
}
form.form fieldset > hr {
  border-top-color: #e5e5e5;
  margin-top: 0.5em;
}
form.form fieldset.no-legend.first-visible > hr {
  display: none;
}
form.form fieldset legend {
  position: relative;
}
form.form fieldset legend label {
  display: inline;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
form.form fieldset legend .toggle-widget {
  cursor: pointer;
  height: 21px;
  background-color: #FFFFFF;
  color: #8C8C8C;
  user-select: none;
  font-size: 10px;
}
form.form fieldset legend .toggle-widget .unfold {
  display: none;
}
form.form fieldset legend .toggle-widget .fold {
  top: 2px;
}
form.form fieldset legend .toggle-widget .glyphicon {
  margin: 0;
}
form.form fieldset.hide-content legend {
  margin-bottom: 0;
}
form.form fieldset.hide-content legend i.unfold {
  display: block;
}
form.form fieldset.hide-content legend i.fold {
  display: none;
}
form.form fieldset.hide-content legend label {
  opacity: 0.6;
}
form.form fieldset.hide-content .content {
  display: none;
}
form.form .fieldset-row {
  display: flex;
}
form.form .fieldset-row.with-legends fieldset > hr {
  margin-top: 35px;
}
form.form .btn.default-list {
  font-size: 1.2em;
  line-height: 1.7em;
  padding: 0 0.5em;
  margin: 7px 7px 0 0;
  position: relative;
  border: none;
}
form.form .btn.default-list:after {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  top: -7px;
  margin-left: 0.5em;
  box-sizing: content-box;
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 7px 1px 7px 0;
}
form.form .decision {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed #b9b9b9;
  background: #fdf7e2;
  box-shadow: inset 0 2px 6px -4px black;
}
form.form .decision section {
  display: flex;
  flex-wrap: wrap;
}
form.form .decision .icon {
  display: flex;
}
form.form .decision .icon .fa {
  margin: auto;
  font-size: 2.2em;
  opacity: 0.4;
  padding: 0.3em;
  color: #cd9756;
}
form.form .decision .markdown {
  flex: 1;
  font-size: 1.2em;
  line-height: 1.4em;
  padding: 0.9em;
  padding-left: 0;
  margin: 0;
}
form.form .decision .markdown p {
  white-space: pre-wrap;
}
form.form .decision .markdown ul,
form.form .decision .markdown ol {
  list-style-type: disc;
  margin: 0.5em 0 0.5em 1.5em;
}
form.form .decision .markdown ul li,
form.form .decision .markdown ol li {
  margin: 0.3em 0;
}
form.form .decision .controls {
  padding: 0.3em;
  justify-content: center;
  border-top: 1px dashed #d0d0d0;
}
form.form .decision .controls .btn {
  margin: 0.3em;
}
form.form .validation-summary {
  font-size: 1em;
}
form.form .validation-summary h4 {
  margin-bottom: 0.4em;
}
form.form .validation-summary p > span {
  white-space: pre-line;
}
form.form section.main .validation-summary {
  border: 1px solid #f3cece;
  border-radius: 2px;
  padding: 0.3em 0.6em;
  margin-bottom: 0.5em;
  align-items: flex-start;
  flex-direction: column;
}
form.form .tab-content {
  border: 1px solid #ddd;
  border-top: 0;
  padding: 0.5em;
  min-height: 10em;
}
form.form .tab-content .customize-position-fix {
  position: absolute;
  margin: 1px 2px;
  z-index: 3;
}
form.form .tab-content .customize-position-fix .customize-list-menu {
  margin: 2px;
}
form.form .tab-content .customize-list-menu {
  margin: 4px;
  display: inline-block;
}
form.form .tab-content .customize-list-menu button {
  padding: 2px 4px;
}
form.form .tab-content .customize-list-menu > .btn > .caret {
  margin-left: 4px;
}
form.form .tab-content .customize-list-menu + .alert {
  border-top: 1px solid gainsboro;
}
form.form .tab-content .alert > .customize-list-menu {
  margin: 0;
  color: #333;
}
form.form .tab-content .alert > .customize-list-menu ul {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
}
form.form .tab-pane .alert {
  margin-bottom: 0;
}
form.form .tab-pane > .row {
  margin: 0;
}
form.form .tab-pane > fieldset.first-visible.no-legend {
  margin-top: 0.7em;
}
form.form .tab-pane > fieldset.first-visible.no-legend > hr {
  display: none;
}
form.form section.main {
  flex: 1;
  overflow: auto;
  border-top: 1px solid gainsboro;
}
form.form section.main > .nav-tabs {
  padding-left: 0.8em;
  padding-top: 0.6em;
  background-color: #fdfcfc;
  box-shadow: inset 2px -2px 8px -7px black;
}
form.form section.main > .nav-tabs .fa {
  font-size: 1.2em;
  vertical-align: middle;
  padding-right: 0.2em;
}
form.form section.main > .tab-content {
  border: none;
  padding: 10px 10px 3em;
}
form.form section.main > .tab-content .attachments-tab {
  overflow: hidden;
}
form.form section.main > .tab-content .attachments-tab .no-attachments {
  display: block;
  margin: 1em;
  opacity: 0.7;
}
form.form section.main > .tab-content .attachments-tab .fileset {
  margin: 1em 0;
  width: 100%;
}
form.form section.main > .tab-content .attachments-tab .fileset .table {
  margin: 1em 0;
}
form.form section.main > .tab-content .attachments-tab .fileset .table th,
form.form section.main > .tab-content .attachments-tab .fileset .table td {
  border: 1px solid #dadada;
}
form.form section.main > .tab-content .attachments-tab .fileset .table th {
  border-width: 1px;
  font-size: 90%;
  text-transform: uppercase;
  background-color: #f0f0f0;
  color: #757575;
  font-weight: bold;
}
form.form section.main > .tab-content .attachments-tab .fileset .table .controls {
  padding: 0.4em;
  background-color: #f0f0f0;
}
form.form section.main > .tab-content .attachments-tab .fileset .table tr.upload td.text {
  border-right: 0;
}
form.form section.main > .tab-content .attachments-tab .fileset .table tr.upload td.progress-cell {
  border-left: 0;
}
form.form section.main > .body.tabbed {
  padding: 0;
  border-top: 1px solid #e8e8e8;
}
@media (max-width: 480px) {
  .form section.main > .tab-content {
    padding: 2px;
  }
  .form section.main > .tab-content fieldset {
    margin: 2px;
  }
}
form.form:after {
  position: absolute;
  top: 200px;
  left: 50%;
  visibility: visible;
  content: '\f110';
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 35px;
  opacity: 0.3;
  margin-left: -17.5px;
  margin-top: -17.5px;
  animation: fa-spin 2s infinite linear;
}
form.form.initialized {
  visibility: visible;
}
form.form.initialized:after {
  display: none;
}
form.form.initialized > section.main {
  display: initial;
}
.form-control.k-input {
  padding: 0;
  width: 100%;
  height: auto;
  line-height: 16px;
}
.form-control.k-input.k-hover,
.form-control.k-input.k-focus {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.form-control.k-input.k-hover {
  border-color: #d0d0d0;
}
.form-control.k-input.k-focus {
  border-color: #66AEE9;
}
.form-control.k-input input.form-control.k-input-inner {
  height: 26px;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 16px;
  color: #555555;
}
.form-control.k-input .k-input-button.k-button {
  height: 26px;
}
.form-control.k-input.k-state-disabled {
  padding: 0;
  opacity: initial;
}
.form-control.k-input.k-state-disabled .k-input {
  border-radius: 3px;
}
.form-control.k-input.k-state-disabled .k-select {
  display: none;
}
#root-layout #main-content form.form footer.main {
  display: none;
}
