html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}

html, body {
	min-height: 100vh;
}

body {
	display: flex;
	flex-direction: column;
}

ol, ul {
	list-style: none;
}

select {
	line-height: 26px;
	height: 26px;
}

legend {
	font-size: 1.2em;
	line-height: 35px;
}

label {
	font-weight: normal;
}
