.list-paginator {
	display: flex;
	padding: 0.3em;
	background-color: #f0f0f0;

	> * {
		flex: 1;
		display: flex;
		align-items: center;
	}

	ul.pagination {
		margin: 0;
		justify-content: flex-start;
	}

	.summary {
		justify-content: center;
	}

	.page-size {
		justify-content: flex-end;
	}

	.summary, label {
		opacity: 0.8;
	}

	label {
		margin: 0;

		&:after {
			content: ': '
		}
	}

	.btn.dropdown-toggle {
		background-color: @pager-bg;
		border-color: @pager-border;
		color: @gray;

		.caret {
			margin-left: 2px;
		}
	}
}
