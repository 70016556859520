.about {
	padding: 15px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	box-shadow: inset 4px 4px 8px -6px black;
	flex: 1;
	flex-wrap: wrap;
	overflow: auto;

	.logo {
		margin-right: 20px;

		img {
			display: block;
		}
	}

	.content {
		flex: 1 1 30em;
		min-width: 5em;
		max-width: 50em;
		margin-bottom: 3em;

		p {
			padding-bottom: 1em;
		}

		.applications {
			width: auto;
		}

		.terms {
			display: inline-block;
			width: 100%;
			max-height: 300px;
			overflow-y: scroll;
		}
	}

	hr {
		margin: 0 0 1em;
	}

	.user-status {
		.icon {
			margin-left: 1em;
			opacity: 0.7;
		}
	}
}

.credits {
	padding: 10px 15px 50px;

	.credit {
		h3 {
			font-size: 1.4em;
			font-weight: normal;
			opacity: 0.8;
			line-height: 2em;
		}

		aside {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			span + span {
				margin-top: 0.3em
			}
		}

		[data-toggle="collapse"] {
			&.collapsed .if-not-collapsed {
				display: none;
			}

			&:not(.collapsed) .if-collapsed {
				display: none;
			}
		}
	}

	.credit + .credit {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px dashed #d8d8d8;
	}

	.licence {
		margin: 5px 0;

		pre {
			padding: 10px;
			white-space: pre-wrap;
		}
	}
}
