.k-dashboard.vertical {
	flex-direction: row;
	min-width: 250px;

	.k-panel-group {
		flex-direction: column;
		flex: 1;

		.k-panel {
			section.main {
				input {
					min-width: 9em;
				}
			}

			.k-linkset {
				flex-direction: column;

				span {
					a {
						display: block;
						padding: 0.7em 0;
						border-bottom: 2px groove rgba(255, 255, 255, 0.18);
					}

					&:first-child {
						a {
							padding-top: 0;
						}
					}

					&:last-child {
						a {
							border: 0;
						}
					}
				}
			}
		}

		.k-panel.list {
			header.main {
				justify-content: center;

				label {
					display: none;
				}
			}

			section.main {
				> .body {
					border-top: 0;
					padding: 0;
				}
			}

			.grid {
				.actions {
					display: none;
				}

				th {
					background: whitesmoke;
				}

				th, td {
					padding: 3px;
				}
			}
		}
	}
}
