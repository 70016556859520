.grid {
	.paste-receiver {
		display: none;
		background: #fff9e7;
		padding: 0.2em 0.5em 0.3em;
		border: 1px solid #f9e9d6;
		vertical-align: top;
		margin: 0.4em -0.5em;
		border-radius: 3px;
	}

	.focus-element {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.paste-receiver.active {
		display: inline-block;
	}

	.empty {
		.paste-receiver {
			margin: 0;
		}
	}
}
