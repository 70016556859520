#main {
	z-index: 1;
	flex: 1;
	display: flex;
	flex-direction: column;
	min-width: 0;
	min-height: 0;
}

#main-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: white;
	min-width: 0;
	min-height: 0;
	overflow: auto;

	> .nav {
		grid-area: nav;
	}

	> .north {
		grid-area: north;
	}

	> .east {
		grid-area: east;
	}

	> .west {
		grid-area: west;
	}

	> form.form {
		grid-area: main;
	}
}
