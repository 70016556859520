@import "~src/style/mixins.less";

.nav-tabs > li > a {
	line-height: 17px;
}

.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	outline: none;
	.transition-none();
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border-bottom-color: #fff;
	background: #fff;
	outline: none;
	.transition-none();
}
