.customize-entity-menu {
	text-align: left;
	margin-left: 0.3em;
	flex-shrink: 0;
}

.customize-entity-menu, .customize-list-menu {
	.dropdown-menu > li > .button-item > label {
		white-space: nowrap;
	}
}
