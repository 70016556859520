.dropdown-menu > li {
	> .button-item {
		display: block;
		padding: 3px 20px;

		> label {
			display: table-cell;
			padding: 3px 20px 3px 0px;
			width: 100%;
		}

		> .btn-group {
			display: table-cell;
		}
	}
}
