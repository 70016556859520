.scroll-container, .task-board-container {
    height: 100%;
}

.k-taskboard-drag-hint .dropdown-menu {
    display: none;
}

.task-board-container {
    .k-taskboard.reorder-is-disabled {
        .k-taskboard-drag-placeholder {
            display: none;
        }

        .k-taskboard-card.card-is-moving {
            display: block !important;
            opacity: 0.5;
        }
    }
    .k-taskboard-content {
        user-select: none;
        padding: 0;
    }

    .k-taskboard-column {
        position: relative;

        &.is-drop-target {
            border-color: #5b5;
        }
    }

    .k-taskboard-columns-container {
        width: 100%;

        .k-taskboard-column {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 300px;
            max-width: 500px;

            .column-count {
                padding: 0.3em 0.6em;
                border-radius: 0.3em;
                margin-right: 0.2em;

                &.background-default {
                    background-color: #eee;
                }
            }
        }
    }

    .k-taskboard-card {
        border: none;
        border-radius: none;
        position: initial;
    }
}

.card-template-container {
    border-radius: 4px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    min-height: 3em;
    cursor: initial;

    .card-status {
        border-radius: 3px 0 0 3px;
        flex-shrink: 0;
        width: 0.5em;
    }

    .card-menu {
        padding: 0.25em 0 0.25em 0.4em;
        flex-shrink: 0;

        .dropdown-menu {
            left: unset !important;
            right: 0.8em;
            min-width: 100px;
        }
    }

    .card-details {
        width: 100%;

        .flex-row {
            flex-direction: row;
            padding: 0.1em 0.3em;

            &.header {
                display: flex;

                .card-title {
                    font-weight: bold;
                    flex-grow: 1;
                    width: 0.5em;
                    line-height: 1.5em;
                    margin: 0.5em 0;
                    font-size: 1.1em;
                }
            }

            &.fields {
                border-top: 1px dotted #ccc;
                width: 100%;

                .field {
                    margin-bottom: 0.4em;
                    display: flex;
                    align-items: center;
                    line-height: 1.2em;

                    .field-label {
                        color: #888;
                        width: 40%;
                        padding-right: 0.5em;
                        display: inline-block;
                        text-align: left;
                    }

                    .field-value {
                        width: 60%;

                        .assistant {
                            padding: 0 0.5em;
                            line-height: 1.4em;
                        }
                    }
                }
            }
        }
    }

    &.is-locked {
        opacity: 0.7;
    }

    &.can-move {
        cursor: move;
    }

    &.is-new {
        border-color: #5b5;
    }

    &.has-changes {
        border-color: #d92;
    }

    &.has-errors {
        border-color: #f33;
    }

    &.is-removed {
        background-color: #f99;
        border-color: #f33;
        cursor: initial;

        .card-title {
            text-decoration: line-through;
        }
    }
}

@media (max-width: @screen-lg-min) {
    .task-board-container .k-taskboard-columns-container .k-taskboard-column {
            min-width: 200px;
    }

    .card-template-container .card-details .flex-row.fields .field {
        flex-direction: column;

        .field-label, .field-value {
            width: 100%;
        }
    }

    .card-details .card-menu .btn-group {
        > .btn:not(.dropdown-toggle,.restore) {
            display: none;
        }

        > .btn.dropdown-toggle {
            border-radius: 3px;
        }
    }
}