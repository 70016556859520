.shortcuts {
	width: 45%;
	margin: 2em;
	border-collapse: collapse;

	thead th {
		padding-bottom: 8px;
	}

	tbody {
		margin: 10px;

		tr {
			border-bottom: solid 1px lightgray;
		}

		tr:first-child {
			border-top: solid 2px darkgray;
		}

		tr:last-child {
			border-bottom: none;
		}

		td {
			padding: 8px;
		}

		td:first-child {
			width: 16em;
		}
	}
}

.forms-shortcuts {
	float: right;
}
