.option-editor {
	display: grid;
	grid-template-columns: 1fr min-content;
	grid-template-rows: 1fr min-content min-content;
	gap: 0.5rem;

	> select {
		grid-column: 1;
		grid-row: 1;
		border: 1px solid #ccc;

		&:focus {
			border-color: #4faef1;
			outline: 2px solid #0095ff2e;
		}

		option {
			padding: 0.3em;
		}
	}

	.side-actions {
		grid-column: 2;
		grid-row: 1;
		background: none;
	}

	.bottom-actions {
		grid-column: 1;
		grid-row: 3;
	}

	.item {
		grid-column: 1;
		grid-row: 2;

		div.field {
			label.control-label {
				width: auto;
			}
		}
	}

	.validation-summary {
		background-color: #fff5f5;

		&:empty {
			display: none;
		}

		li {
			& + li {
				margin-top: 0.3em;
			}

			em {
				font-style: normal;
				font-weight: bold;

				&:after {
					content: ':'
				}
			}
		}
	}
}

.option-editor:not(.disabled) {
	.field.edit {
		min-width: 0 !important;
	}
}

.option-editor.disabled {
	select:focus {
		outline: none;
	}
}

.table tbody tr td {
	.option-editor > select {
		height: 4em;
	}
}
