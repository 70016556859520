@import "~src/style/variables.less";

@connectToQuickBooksId: '65e393f7-24f0-4838-ae16-b311b017bad6';

header.alert {
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #b7b7b7;
	padding: 0.7em 0.8em 0.5em;
	margin: 0;
	font-size: 1.2em;

	pre {
		margin: 0.5em 0;
		padding: 0.5em;
	}
}

header.main {
	display: flex;
	align-items: center;
	background: #fdfcfc;
	flex-wrap: wrap;
	flex-shrink: 0;
	padding: 0 0 @gutter-small @gutter-small;
	min-width: 0;

	> label {
		margin: @gutter-small @gutter-small 0 0;
		white-space: nowrap;
		flex: 1 1 5em;
		font-weight: normal;
		line-height: normal;
		font-size: 1.7em;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;

		> span {
			overflow: hidden;
			text-overflow: ellipsis;
			margin-left: .25em;
		}

		> .assistant {
			margin-left: .4em;
			min-width: 24px;
		}

		em {
			font-style: normal;

			&.with-name {
				opacity: 0.8;

				&:after {
					content: ':';
				}

				&:empty {
					display: none;
				}
			}
		}
	}

	.controls {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.btn {
			&:hover, &:focus {
				text-decoration: none;
			}
		}

		> .btn, > .btn-group {
			margin: @gutter-small @gutter-small 0 0;
		}

		.btn[data-uid='@{connectToQuickBooksId}'] {
			background: url("../../images/quickbooks/C2QB_white_btn_lg_default.png") no-repeat center center;
			background-size: cover;
			width: 137.5px;
			height: 24px;
			border: 0;
			padding: 0;

			> span {
				display: none;
			}

			&:hover {
				background-image: url("../../images/quickbooks/C2QB_white_btn_lg_hover.png");
			}
		}

		.btn.help {
			font-size: @line-height-small;
			padding: 0;
			color: @brand-success;

			&:hover {
				color: darken(@brand-success, 10%)
			}
		}

		.btn-group > .dropdown-menu {
			min-width: unset;

			li > button {
				padding: 4px 10px;
				background: none;
				border: none;
				color: #333;
				text-align: left;
				width: 100%;

				span.fa {
					padding-right: 2px;
				}

				&:hover {
					background: #eee;
				}
			}
		}
	}

	> .customize-entity-menu {
		margin: @gutter-small @gutter-small 0 0;
	}
}
