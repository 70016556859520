#main-content > form > section.main > .grid {
	border: none;

	.grid-body {
		table {
			thead tr th, tbody tr td {
				@edge-padding: 0.7em;

				&:first-child {
					padding-left: @edge-padding;
				}

				&:last-child {
					padding-right: @edge-padding;
				}
			}
		}

		.grid-buttons {
			padding: 0.4em 0.7em 1em
		}
	}

	.list-paginator {
		padding: 0.3em 0.7em;
	}

	.filter-search header {
		padding: 0.4em 0.6em;
	}
}
