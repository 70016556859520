.grid-error {
	border-top: 1px solid #999;
	border-bottom: 1px solid #ccc;

	label {
		background: #FFFAD5;
		padding: 12px 4px;
		color: #666;
		font-size: 0.8em;
		display: block;
	}

	span {
		font-style: italic;
		display: block;
		padding-top: 0.3em;
	}
}
