@import "~src/style/variables.less";

#top-bar {
	.messagebox {
		@frame-color: #eff0f1;

		.badge {
			position: absolute;
			right: 1px;
			top: 1px;
			background-color: red;
			font-size: 10px;
			padding: 2px 4px;
			display: none;
		}

		.dropdown-toggle {
			position: relative;
		}

		.dropdown-menu {
			padding: 0;
			width: 350px;
			margin-top: -2px;
			border-color: @frame-color;
		}

		header.controls {
			display: flex;
			justify-content: space-between;
			background-color: @frame-color;
			border-bottom: 1px solid #dedede;
			padding: 0.3em;

			> a {
				padding: 5px;
				background-color: transparent;
			}

			&:hover {
				a {
					color: @link-color;
				}
			}
		}

		.messages {
			max-height: 60vh;
			overflow-x: auto;
			overflow-y: scroll;

			.message {
				display: block;
				text-decoration: none;
				cursor: default;
				position: relative;
				padding: 8px 25px;
				white-space: normal;
				border-bottom: 1px solid #eee;
				background-color: #fef8f1;
				color: #333;

				.message-icon {
					color: #a9a9a9;
					position: absolute;
					left: 6px;
					top: 9px;
					font-size: 14px;
				}

				.message-header {
					font-size: 11px;
					font-weight: bold;
					color: #6f6f6f;
					display: flex;

					.message-type {
						flex: 1;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.message-creation {
						white-space: nowrap;
					}
				}

				.message-body {
					clear: both;
					padding-top: 5px;
					white-space: pre-wrap;
					word-wrap: break-word;
				}

				&:hover .message-close {
					color: lighten(@btn-default-color, 40%);
				}

				.message-mark-read {
					position: absolute;
					padding: 6px;
					right: 0;
					top: 4px;
					cursor: default;
					font-size: 10px;
					color: @btn-default-color;
					opacity: 0.5;

					&:hover {
						opacity: 1;
					}
				}

				.message-actions {
					text-align: right;
					margin-top: 0.3em;
					border-top: 1px dashed #eee;
					padding-top: 0.3em;

					&:empty {
						display: none;
					}
				}
			}

			.message:last-child {
				border-bottom: 0;
			}

			.message.read {
				background-color: inherit;

				.message-icon {
					color: @btn-default-border;
				}

				.message-mark-read {
					display: none;
				}

				.message-header {
					font-weight: normal;
				}
			}

			a.message-body {
				display: block;
			}

			li.text {
				padding: 8px 0;
				text-align: center;
				color: lighten(@btn-default-color, 30%);
			}

			li.message-list-unread.with-read {
				border-bottom: 1px dashed #c5c5c5;
			}
		}

		.messages.empty {
			overflow: hidden;
		}

		&.unacknowledged {
			.dropdown-toggle {
				background-color: rgba(198, 243, 50, 0.75);
				padding: 7px 9px;

				> .fa {
					color: black;
				}
			}

			.badge, .clear-all, .messages {
				display: block;
			}
		}

		.dropdown.open {
			opacity: 1;

			.dropdown-toggle {
				background-color: @frame-color;

				> .fa {
					color: black;
				}
			}
		}
	}
}
