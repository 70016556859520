@import "~src/style/variables.less";

.filter-search {
	background-color: #f0f0f0;

	header {
		display: flex;
		align-items: center;
		padding: 0.4em;

		.toggle-widget {
			padding: 0.4em 0.5em 0.2em;
			border-radius: 2px;
			border: 1px solid #ccc;
			margin-right: 0.5em;
			background-color: white;
			cursor: pointer;

			&:hover {
				background-color: darken(white, 10%);
			}
		}

		label {
			opacity: 0.9;
			padding: 0.3em;
			margin: 0;
		}

		.controls {
			margin: 0 1em;
		}

		.filter-controls {
			display: flex;
			flex: 1;

			.user-filter {
				margin: 0 0 0 auto;
			}
		}
	}

	section.content {
		padding: 0.5em 0.7em 0;
		border-top: 2px groove rgba(255, 255, 255, 0.29);

		> ul > li {
			float: left;
			padding-right: 2em;
			margin-bottom: 0;
			min-height: 55px;
			min-width: @size-large;
		}

		label {
			color: #666;
		}

		.required {
			em.required {
				display: inline;
			}

			label {
				font-weight: bold;
			}
		}
	}

	&.hide-content {
		header {
			.toggle-widget {
				background-color: rgba(255, 255, 255, 0.3);

				&:hover {
					background-color: darken(rgba(255, 255, 255, 0.3), 10%);
				}
			}

			.controls {
				display: none;
			}
		}

		section.content {
			display: none;
		}
	}
}
