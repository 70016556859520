@start-panel-width: 404px;
@start-panel-max-height: 780px;

#start-main {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.start-panel {
		margin: .5em 0;
		width: @start-panel-width;
		overflow: hidden;
		padding: 0;
		border: 1px solid #ccc;
		border-radius: 5px;
		background-color: #fff;

		form {
			margin: 10px 60px;
			min-height: 8em;
		}

		.banner {
			max-width: @start-panel-width;
			display: block;
			margin: 0 auto;
		}

		fieldset legend, .form-tabs {
			display: none;
		}

		.form-group {
			margin-bottom: 1em;
		}

		.nav-links {
			text-align: right;
			padding: 10px 20px;

			> li {
				display: inline-block;

				&:not(:last-child):after {
					content: " |";
				}
			}
		}

		.auth-provider-form {
			text-align: center;
			background: #F7F7F7;
			border-top: 1px solid #ccc;
			padding: 1em 0 1em;
			margin: 0;

			button.auth-provider img {
				margin-right: 0.3em;
			}
		}

		> .start-content {
			> .alert {
				margin-top: 0px;
				margin-left: 0px;
				margin-right: 0px;
			}

			.alert {
				margin-bottom: 12px;
			}

			.form {
				header.main, section.main, footer.main {
					padding: 0;
					border: none;
					background: none;
				}

				header.main {
					color: #000;
					margin-bottom: 1em;

					> label {
						padding: 0;
					}

					> .controls {
						display: none;
					}
				}

				section.main {
					overflow: hidden;
				}

				.control-label {
					font-weight: normal;
					white-space: nowrap;
					width: auto;

					> em {
						display: none !important;
					}

					&:after {
						content: '';
					}
				}

				.help-inline {
					margin-left: -8px;
				}
			}
		}

		> .login-content {
			.message {
				margin-bottom: 5px;
				background-color: #ffc;
				padding: 5px;
				border-radius: 4px;
				text-align: center;
			}
		}

		#announcements {
			margin: .3em .5em;

			> section {
				padding: .1em;
				max-height: 300px;
				overflow-y: auto;

				> .announcement {
					display: flex;
					flex-direction: column;
					flex: 1;
					border: 1px solid #eee;
					border-radius: 4px;
					padding: .5em;
					background: #fefefe;
					margin-bottom: .5em;

					> .header {
						display: flex;
						flex-direction: row;
						padding-bottom: .2em;
						border-bottom: 1px dotted #eee;

						> .title {
							flex-grow: 1;
							font-weight: bold;
						}

						> .date {
							flex-shrink: 0;
							justify-content: flex-end;
							font-size: 10px;
							text-align: right;
						}
					}

					> .message {
						margin: 0.2em;
						padding: 0;

						p {
							white-space: pre-wrap;
						}
					}
				}
			}
		}
	}

	small {
		font-size: 80%;
	}
}

@media (max-height: @start-panel-max-height) {
	#start-main .start-panel {
		#announcements > section {
			max-height: inherit;
		}
	}
}

@media (min-height: (@start-panel-max-height + 100)) {
	#start-main {
		justify-content: flex-start;

		.start-panel {
			margin-top: (@start-panel-width / 4);
		}
	}
}

@media (max-width: @start-panel-width) {
	#start-main {
		justify-content: flex-start;

		.start-panel {
			width: 100%;
			top: 0;
			border: none;
			border-radius: 0;
			margin: 0;

			.banner {
				max-width: 100%;
			}

			> .start-content, > .login-content {
				form {
					margin: .8em .16em;
				}
			}

			button {
				width: 100%;
			}

			.nav-links {
				padding: 0;
				text-align: center;
			}

			#announcements > section {
				max-height: inherit;
			}
		}
	}
}
