#main-content, .dashboard-form {
	> .k-dashboard {
		background-color: #f0f0f0;
		min-height: 0;
		min-width: 0;

		.k-panel {
			> header {
				background: #fdfcfc;
				border: 1px solid #ccc;
				border-width: 1px 0;
				box-shadow: 0 0px 5px -3px black;
			}

			.grid {
				background-color: rgba(255, 255, 255, 0.88);
			}
		}
	}

	> .k-dashboard + form.form {
		border: 1px solid #b3b3b3;
		box-shadow: 0 0 9px -4px black;

		.decision {
			box-shadow: none;
		}
	}
}

.dashboard-customize-menu {
	margin-top: 0.6rem;
	margin-right: 0.6rem;
}