.print {
	padding: 10px;

	label {
		cursor: text;
	}
}

.print-button {
	.btn {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}
