#main-menu.dark {
	.scrollbars() {
		&::-webkit-scrollbar-track {
			background: hsl(226, 3%, 28%);
		}

		&::-webkit-scrollbar-thumb {
			background: hsl(223, 3%, 50%);
		}

		&::-webkit-scrollbar-thumb:window-inactive {
			background: hsl(223, 3%, 50%);
		}

		&::-webkit-scrollbar-corner {
			background: hsl(224, 3%, 28%);
		}
	}

	background-color: hsl(224, 3%, 28%);

	#toggle-menu {
		color: white;

		&:hover {
			.box-shadow(@blur: 30px, @color:hsla(0, 0%, 100%, 0.2));
		}

		&.active {
			background-color: hsl(223, 3%, 50%);
		}
	}

	.location {
		border-color: #2f2f2f;
		color: hsla(0, 0%, 100%, 0.25);

		a {
			border-color: hsl(0, 0%, 20%);
			color: hsl(0, 0%, 80%);
			background-color: hsl(227, 3%, 34%);
		}

		a:hover {
			background-color: #b4b4b4;
			color: hsl(0, 0%, 100%);
		}

		a.active {
			background-color: hsl(200, 97%, 44%);
			color: hsl(0, 0%, 100%);
		}
	}

	.search {
		background-color: hsla(0, 0%, 100%, 0.05);
		border-top: 1px solid hsla(0, 0%, 100%, 0.14);
		border-bottom: 2px groove hsla(0, 0%, 48%, 0.4);

		input {
			background-color: hsla(0, 0%, 21%, 0.46);
			color: white;
			border-color: hsl(0, 0%, 12%);
		}
	}

	.fa {
		color: white;
	}

	.empty {
		color: hsla(0, 0%, 100%, 0.48);
	}

	section.items {
		.scrollbars();

		ul.level-1 {
			.scrollbars();
		}

		ul.level-2 {
			.scrollbars();
		}

		li {
			.item {
				.item-label {
					color: hsla(0, 0%, 100%, 0.87);
				}
			}
		}

		li.recent {
			> .item {
				.item-label {
					color: hsla(0, 0%, 100%, 0.5);
				}
			}
		}

		li:not(.recent) {
			&:not(.active-flyout) {
				.item:hover {
					background-color: hsla(0, 0%, 100%, 0.03);
				}
			}

			&.expanded {
				&.active:not(.parent):not(.filter-hit) {
					background-color: hsl(200, 97%, 45%);
				}
			}
		}
	}

	.customize {
		.edit {
			background-color: hsla(46, 100%, 50%, 0.39);
		}

		.add {
			background-color: hsla(121, 100%, 50%, 0.39);
		}
	}
}

#main-menu.dark.collapsed {
	@header-color: hsl(227, 3%, 38%);

	.flyout-header {
		background: @header-color;
	}

	.flyout-list {
		background: hsl(222, 3%, 29%);

		&.flyout-menu {
			box-shadow: 0px 5px 15px 0px hsla(0, 0%, 0%, 0.54);
		}

		&.flyout-drawer {
			box-shadow: 0px 10px 30px black;
		}
	}

	.active-flyout {
		background: @header-color;
		box-shadow: inset 5px 1px 2px 0px hsla(0, 0%, 0%, 0.58);

		&.recent {
			.flyout-list {
				background: @header-color;
				border-top: 2px groove hsla(0, 0%, 48%, 0.4);
			}
		}

		&:hover {
			background: @header-color;
		}
	}
}

#main-menu.dark:not(.filtered) {
	section.items {
		li:not(.recent) {
			&.expanded {
				&.parent {
					ul:not(.flyout-list) {
						background-color: hsla(0, 0%, 0%, 0.12);
					}
				}
			}
		}
	}
}

#main-menu.dark.filtered {
	.filter-hit {
		background-color: hsl(219, 3%, 19%);

		> .item {
			border-left-color: hsl(39, 84%, 41%);
		}
	}
}

#main-menu.dark.filtered.without-matches {
	.search {
		background-color: hsl(0, 16%, 46%);

		input {
			outline-color: hsl(0, 39%, 32%);
		}
	}
}
