@import "~src/style/variables.less";

#main-menu.collapsed {
	@collapse-size: 40px;
	@flyout-size: 260px;

	.collapsed-button() {
		display: flex;
		width: @collapse-size;
		height: @collapse-size;
		padding: 0;
		margin: 0;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		opacity: 0.7;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}

	margin-left: -(@menu-width - @collapse-size);

	> header {
		justify-content: flex-end;

		.logo {
			display: none;
		}

		#toggle-menu {
			.collapsed-button();
		}
	}

	section.search{
		&.flyout {
			padding: 0.5em;

			.clear {
				display: block;
			}
		}

		&:not(.flyout) {
			box-shadow: none;
			justify-content: flex-end;
			padding: 0;

			input, .clear {
				display: none;
			}

			#toggle-search {
				.collapsed-button();
			}
		}
	}

	section.items {
		margin-top: 0.5em;

		ul.level-1 {
			.recent {
				border-bottom: 0;
			}
		}

		> ul:not(.flyout-list) > li {
			> .item {
				padding: 0;
				justify-content: flex-end;

				.icon {
					font-size: 1.2em;
					.collapsed-button();
				}

				.item-label, .tree {
					display: none;
				}
			}

			&.parent.expanded {
				> .item {
					.tree-collapse {
						display: none;
					}
				}

				> ul {
					display: none;
				}
			}
		}

		&::-webkit-scrollbar {
			width: 5px;
		}
	}

	.flyout {
		z-index: 2;
		width: @flyout-size;
		position: absolute;

		&.flyout-menu {
			left: @collapse-size;
		}

		&.flyout-drawer {
			left: 0;
		}
	}

	.flyout-header {
		display: flex !important;
		height: @collapse-size;
		align-items: center;
		padding: 0 1em;
	}

	.flyout-list {
		display: block !important;
		overflow: auto;
	}

	.active-flyout {
		> .item {
			.icon {
				opacity: 1 !important;
			}

			&:hover {
				background: none;
			}
		}
	}

	.customize {
		display: none;
	}

	section.empty {
		z-index: 3;
		margin-top: @collapse-size;
		margin-left: @menu-width - @collapse-size;
		width: @flyout-size;
	}
}
