@import "~src/style/variables.less";

.k-dashboard.horizontal {
	flex-direction: column;

	.k-panel-group {
		flex-direction: row;

		.k-panel {
			flex: 1;
		}
	}
}

.k-dashboard.horizontal, header.dashboard-navigation {
	.k-linkset {
		flex-wrap: wrap;

		span {
			a {
				white-space: nowrap;
				display: inline-block;
				padding: 0.4em;
			}

			&::after {
				content: '\00A0';
				display: inline-block;
				border-right: 1px solid #ccc;
				margin: 0 0.4em;
				width: 0;
			}

			&:last-child::after {
				display: none;
			}
		}
	}
}

@media screen and (max-width: @screen-sm-min) {
	.k-dashboard.horizontal > .k-panel-group {
		flex-direction: column;

		> .k-panel.tree {
			display: none;
		}
	}
}
