form.form {
	&:after {
		@size: 35px;

		position: absolute;
		top: 200px;
		left: 50%;
		visibility: visible;
		content: '\f110';
		font: normal normal normal 14px/1 FontAwesome;
		font-size: @size;
		opacity: 0.3;
		margin-left: -(@size / 2);
		margin-top: -(@size / 2);
		animation: fa-spin 2s infinite linear;
	}
}

form.form.initialized {
	visibility: visible;

	&:after {
		display: none;
	}

	> section.main {
		display: initial;
	}
}
