.loadmask {
    z-index: 100;
    position: absolute;
    top:0;
    left:0;
    -moz-opacity: 0.5;
    opacity: .50;
    filter: alpha(opacity=50);
    background-color: #CCC;
    width: 100%;
    height: 100%;
    zoom: 1;
}
.loadmask-msg {
    z-index: 20001;
    position: absolute;
    top: 0;
    left: 0;
    border:1px solid #6593cf;
    background: #c3daf9;
    padding:2px;
}
.loadmask-msg div {
    padding:5px 10px 5px 25px;
    background: #fbfbfb url('./loading.gif') no-repeat 5px 5px;
    line-height: 16px;
	border:1px solid #a3bad9;
    color:#222;
    font:normal 11px tahoma, arial, helvetica, sans-serif;
    cursor:wait;
}
.masked {
    overflow: hidden !important;
}
.masked-relative {
    position: relative !important;
}
.masked-hidden {
    visibility: hidden !important;
}
