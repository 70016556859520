.transition-opacity(@time: 0.3s, @type: ease-out) {
	-moz-transition: opacity @time @type;
	-o-transition: opacity @time @type;
	-webkit-transition: opacity @time @type;
	transition: opacity @time @type;
}

.transition-all(@time: 0.3s, @type: ease-out) {
	-moz-transition: all @time @type;
	-o-transition: all @time @type;
	-webkit-transition: all @time @type;
	transition: all @time @type;
}

.transition-none() {
	-moz-transition: none;
	-o-transition: none;
	-webkit-transition: none;
	transition: none;
}

.stroke(@color: #000) {
	@pos: 1px;
	@neg: @pos * -1;
	text-shadow: @neg @neg 0 @color, @pos @neg 0 @color, @neg @pos 0 @color, @pos @pos 0 @color;
}

.box-shadow(@x: 0px, @y: 0px, @blur: 10px, @spread: 0px, @color: rgba(50, 50, 50, 0.4)) {
	-webkit-box-shadow: @x @y @blur @spread @color;
	-moz-box-shadow: @x @y @blur @spread @color;
	box-shadow: @x @y @blur @spread @color;
}
