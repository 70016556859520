.grid {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid gainsboro;

	& > aside {
		flex-shrink: 0;
		border-bottom: 1px solid gainsboro;

		&.filter-search {
			flex: 0 0 auto;
			max-height: 40vh;
			overflow-y: auto;

			&:not(.hide-content) {
				min-height: 8.7em;
			}
		}
	}

	.grid-body {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		min-height: 6.3em;
		position: relative;

		.scroll-container {
			overflow: auto;
			width: 0;
			min-width: 100%;
			min-height: 5em;

			.btn-group {
				position: initial;

				.dropdown-menu {
					&.above {
						margin: -2px 0;
					}

					> li {
						> a, > button {
							padding: 3px 20px;
							clear: both;
							font-weight: normal;
							line-height: 16px;
							color: #333333;
							white-space: nowrap;
							background: none;
							border: none;
							display: block;
							box-sizing: border-box;
							width: 100%;
							text-align: left;

							&:hover {
								background: #f5f5f5;
							}

							&:disabled {
								opacity: 0.4;
							}
						}
					}
				}
			}
		}

		.grid-buttons {
			padding: 0.4em;
			min-width: 0;
		}
	}

	.alert {
		border-radius: 0;
		border: none;
	}

	.save-items {
		text-align: right;
		padding: 0.3em;
		background-color: #fbf9f9;
		border-bottom: 1px solid gainsboro;
	}

	.select-panel {
		display: flex;
		align-items: center;
		padding: 0.7em;
		background-color: #fcf8e3;
		border: 1px solid #ddd;
		border-bottom: none;

		> * {
			flex: 1;
			display: flex;
			margin-bottom: 0.3em;
		}

		.controls.near {
			margin-right: 0.5em;
		}

		.summary {
			justify-content: center;
			font-size: 1.1em;
			color: #333;
			opacity: 0.8;
			white-space: nowrap;
		}

		em {
			font-style: normal;
			font-weight: bold;
		}

		.controls.far {
			justify-content: flex-end;
		}

		aside button + button {
			margin-left: 0.3em;
		}
	}
}

.grid.with-filter {
	.alert.empty {
		background: white;
		border-bottom: 1px solid #d9d9d9;
		color: #888;
	}
}

.grid-edit, .grid-select-button {
	text-align: right;
	position: absolute;
	right: 0;
	padding-top: 10px;
	padding-right: 5px;
}
