.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 5px;
  clear: both;
}
.alert p {
  margin: 0 0 0.5em;
}
.alert h1,
.alert h2,
.alert h3,
.alert h4,
.alert h5,
.alert h6 {
  font-weight: normal;
}
.alert h6 {
  font-size: 1.05em;
}
.alert h5 {
  font-size: 1.1em;
}
.alert h4 {
  margin: 0 0 0.5em -0.2em;
  line-height: 20px;
  font-size: 1.2em;
}
.alert h3 {
  font-size: 1.3em;
}
.alert h2 {
  font-size: 1.4em;
}
.alert h1 {
  font-size: 1.5em;
}
.alert ol,
.alert ul {
  list-style-position: outside;
  margin: 10px 0 10px 25px;
}
.alert ol li,
.alert ul li {
  margin: 0.3em 0;
}
.alert ol {
  list-style-type: decimal;
}
.alert ul {
  list-style-type: disc;
}
.alert ul.dropdown-menu {
  list-style-type: none;
  margin: 2px 0 0;
}
.alert ul.dropdown-menu li {
  margin: 0;
}
.alert.error {
  color: #864c4b;
}
.btn {
  white-space: nowrap;
}
.btn-default {
  background: #f5f5f5;
}
.ui-dialog {
  position: fixed;
}
.ui-dialog .ui-dialog-titlebar {
  position: absolute !important;
  background: none;
  border: none;
  top: 14px;
  right: 24px;
  z-index: 1;
}
.ui-dialog .ui-dialog-titlebar > span {
  display: none;
}
.ui-dialog .ui-dialog-titlebar > button {
  margin-top: -6px;
  height: 26px;
  width: 25px;
}
.ui-dialog .ui-dialog-content {
  padding: 0.3em 0.3em 0 !important;
}
.form-group.error input,
.form-group.error select,
.form-group.error textarea,
.form-group.error .select2-container > a,
.form-group.error .k-picker-wrap,
.form-group.error .k-picker-wrap.k-state-hover,
.form-group.error .k-picker-wrap.k-state-focused,
.form-group.error .select2-drop-active,
.select2-drop.error {
  border-color: #b94a48;
}
.form-horizontal .control-label {
  text-align: left;
  margin-bottom: 0;
  padding-top: 5px;
}
.form-horizontal .form-group {
  margin-top: 0;
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  .form-horizontal .control-label {
    text-align: right;
  }
}
.input-append .input-group-addon {
  height: 16px;
  line-height: 16px;
}
.input-append .popover .popover-content {
  font-size: 12px;
}
.dropdown-menu > li > .button-item {
  display: block;
  padding: 3px 20px;
}
.dropdown-menu > li > .button-item > label {
  display: table-cell;
  padding: 3px 20px 3px 0px;
  width: 100%;
}
.dropdown-menu > li > .button-item > .btn-group {
  display: table-cell;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}
.nav-tabs > li > a {
  line-height: 17px;
}
.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  outline: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-bottom-color: #fff;
  background: #fff;
  outline: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.popover .popover-title {
  padding: 0.5em 0.7em;
  line-height: normal;
  font-weight: normal;
  font-size: 1.1em;
}
.popover .popover-content {
  padding: 0.5em 0.8em;
  max-height: 10em;
  overflow: auto;
}
kbd {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  color: #333;
  display: inline-block;
  font-family: inherit;
  font-size: 11px;
  line-height: 1.3;
  cursor: default;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  text-shadow: 0 1px 0 #fff;
}
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
html,
body {
  min-height: 100vh;
}
body {
  display: flex;
  flex-direction: column;
}
ol,
ul {
  list-style: none;
}
select {
  line-height: 26px;
  height: 26px;
}
legend {
  font-size: 1.2em;
  line-height: 35px;
}
label {
  font-weight: normal;
}
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}
.clearfix:after {
  clear: both;
}
.customize-entity-menu {
  text-align: left;
  margin-left: 0.3em;
  flex-shrink: 0;
}
.customize-entity-menu .dropdown-menu > li > .button-item > label,
.customize-list-menu .dropdown-menu > li > .button-item > label {
  white-space: nowrap;
}
.customize-message {
  background-color: #fcf8e3;
  color: #886d3e;
  text-align: center;
  padding: 5px;
  position: fixed;
  top: 0;
  left: 50%;
  width: 360px;
  z-index: 10000;
  margin-left: -180px;
  border-radius: 0 0 5px 5px;
  opacity: 0.7;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  border: 1px solid #CECECE;
  border-top: 0;
  cursor: default;
}
.customize-message:hover {
  opacity: 1;
}
.loadmask-msg {
  border: 0;
  background: none;
  padding: 0;
}
.select2-container-multi .select2-choices .select2-search-field input {
  height: auto !important;
}
.select2-container-multi .select2-choices .select2-search-choice {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.select2-results li.select2-result-unselectable > .select2-result-label {
  font-weight: bold;
  cursor: default;
}
.select2-results .select2-result-label > .select2-description {
  font-size: 0.9em;
  color: #666;
}
.select2-results .select2-highlighted .select2-result-label > .select2-description {
  color: #eee;
}
.select2-container {
  width: 100% !important;
}
.select2-search-choice a {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.text-nowrap {
  white-space: nowrap;
}
.two-factor .qr-code img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 160px;
}
.two-factor .qr-code .manual-code {
  text-align: center;
  font-style: italic;
  font-weight: bolder;
}
#start-main header.main {
  display: none;
}
#app {
  background-color: #fff;
  font-size: 24.5px;
}
#root-layout {
  display: flex;
  height: 100vh;
}
header.alert {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #b7b7b7;
  padding: 0.7em 0.8em 0.5em;
  margin: 0;
  font-size: 1.2em;
}
header.alert pre {
  margin: 0.5em 0;
  padding: 0.5em;
}
header.main {
  display: flex;
  align-items: center;
  background: #fdfcfc;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0 0 7px 7px;
  min-width: 0;
}
header.main > label {
  margin: 7px 7px 0 0;
  white-space: nowrap;
  flex: 1 1 5em;
  font-weight: normal;
  line-height: normal;
  font-size: 1.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
header.main > label > span {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.25em;
}
header.main > label > .assistant {
  margin-left: 0.4em;
  min-width: 24px;
}
header.main > label em {
  font-style: normal;
}
header.main > label em.with-name {
  opacity: 0.8;
}
header.main > label em.with-name:after {
  content: ':';
}
header.main > label em.with-name:empty {
  display: none;
}
header.main .controls {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
header.main .controls .btn:hover,
header.main .controls .btn:focus {
  text-decoration: none;
}
header.main .controls > .btn,
header.main .controls > .btn-group {
  margin: 7px 7px 0 0;
}
header.main .controls .btn[data-uid='65e393f7-24f0-4838-ae16-b311b017bad6'] {
  background: url("../images/quickbooks/C2QB_white_btn_lg_default.png") no-repeat center center;
  background-size: cover;
  width: 137.5px;
  height: 24px;
  border: 0;
  padding: 0;
}
header.main .controls .btn[data-uid='65e393f7-24f0-4838-ae16-b311b017bad6'] > span {
  display: none;
}
header.main .controls .btn[data-uid='65e393f7-24f0-4838-ae16-b311b017bad6']:hover {
  background-image: url("../images/quickbooks/C2QB_white_btn_lg_hover.png");
}
header.main .controls .btn.help {
  font-size: 20px;
  padding: 0;
  color: #5cb85c;
}
header.main .controls .btn.help:hover {
  color: #449d44;
}
header.main .controls .btn-group > .dropdown-menu {
  min-width: unset;
}
header.main .controls .btn-group > .dropdown-menu li > button {
  padding: 4px 10px;
  background: none;
  border: none;
  color: #333;
  text-align: left;
  width: 100%;
}
header.main .controls .btn-group > .dropdown-menu li > button span.fa {
  padding-right: 2px;
}
header.main .controls .btn-group > .dropdown-menu li > button:hover {
  background: #eee;
}
header.main > .customize-entity-menu {
  margin: 7px 7px 0 0;
}
#main {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}
#main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 0;
  min-height: 0;
  overflow: auto;
}
#main-content > .nav {
  grid-area: nav;
}
#main-content > .north {
  grid-area: north;
}
#main-content > .east {
  grid-area: east;
}
#main-content > .west {
  grid-area: west;
}
#main-content > form.form {
  grid-area: main;
}
.print {
  padding: 10px;
}
.print label {
  cursor: text;
}
.print-button .btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
#reportViewer {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
  font-family: 'segoe ui', 'ms sans serif';
  overflow: hidden;
}
.report .report-header {
  padding: 2px 0 10px;
}
.report .report-footer {
  padding: 10px 0;
}
.report .report-table {
  border-top: solid 1px gainsboro;
}
.report .report-table thead th {
  vertical-align: auto;
}
.report .report-table thead th .glyphicon.sort-ascending,
.report .report-table thead th .glyphicon.sort-descending {
  display: none;
}
.report .report-table thead th.sort-ascending .glyphicon.sort-ascending {
  display: inline;
}
.report .report-table thead th.sort-descending .glyphicon.sort-descending {
  display: inline;
}
.report .report-table thead th.column-group-header {
  text-align: center;
}
.report .report-table td,
.report .report-table th {
  text-align: left;
  vertical-align: middle;
}
.report .report-table td.boolean > .report-value,
.report .report-table th.boolean > .report-value {
  justify-content: center;
}
.report .report-table td.integer > .report-value,
.report .report-table th.integer > .report-value,
.report .report-table td.float > .report-value,
.report .report-table th.float > .report-value {
  justify-content: flex-end;
}
.report .report-table td.image-url img,
.report .report-table th.image-url img {
  max-width: 260px;
  max-height: 180px;
}
.report .report-table td .report-value,
.report .report-table th .report-value {
  display: flex;
  flex: 1;
}
.report .report-table td .report-value .display,
.report .report-table th .report-value .display {
  padding: 0.4em 0 0.2em;
}
.report .report-table tr[class*="group-footer"] td {
  font-weight: bold;
}
.report .report-table tr.report-total td,
.report .report-table tr.report-total-row td {
  font-weight: 900;
  font-size: 1.1em;
}
.report .report-table tr.report-total td {
  border-bottom: double 3px gainsboro;
}
#top-bar {
  display: flex;
  flex-shrink: 0;
  color: #fff;
  background-color: #464c5b;
}
#top-bar .top-controls {
  flex: 1;
  display: flex;
  flex-direction: row;
  min-width: 0;
  background: linear-gradient(to right, #047ab1 45%, #005680 95%);
  background-color: #005680;
  border-top: 1px solid #3c3c3c;
  border-bottom: 1px solid #505050;
}
#top-bar .server-name {
  align-self: center;
  border: 1px dashed #cecece;
  border-radius: 3px;
  background: #31291f;
  padding: 0.3em 0.5em;
  font-size: 10px;
  opacity: 0.6;
  margin: 0.3em;
  color: #fff;
  white-space: nowrap;
}
#top-bar .server-name .fa,
#top-bar .server-name .glyphicon {
  padding: 0 0.3em;
  font-size: 0.9em;
}
#top-bar .top-list {
  display: flex;
  align-items: center;
  color: inherit;
}
#top-bar .top-list .fa,
#top-bar .top-list .glyphicon {
  color: inherit;
  font-size: 16px;
  vertical-align: text-bottom;
}
#top-bar .top-list .user-avatar.customizing {
  background: #f0ad4e;
  border: 1px solid rgba(255, 255, 255, 0.44);
  border-radius: 3px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.37);
}
#top-bar .top-list .user-avatar.customizing:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
#top-bar .top-list > li > div {
  opacity: 0.9;
}
#top-bar .top-list > li > div > a {
  display: block;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  margin: 0 2px 0;
  padding: 7px 7px;
  color: inherit;
  border: 1px solid transparent;
  text-decoration: none;
}
#top-bar .top-list > li:hover > div {
  opacity: 1;
}
#top-bar .top-list > li:hover > div > a {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.16);
}
#top-bar .top-list > li.selected > div {
  opacity: 1;
}
#top-bar .top-list > li.selected > div > a {
  background: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.16);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}
#top-bar .top-menu {
  padding: 0.2em 0;
  flex: 1;
  overflow: auto;
  scrollbar-width: thin;
}
#top-bar .top-menu::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#top-bar .top-menu::-webkit-scrollbar-thumb {
  background: #ffffffa3;
}
#top-bar .top-menu::-webkit-scrollbar-track {
  background: #0000003d;
}
#top-bar .top-menu li span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-width: 5em;
  overflow: hidden;
}
#top-bar .options {
  justify-content: flex-end;
}
#top-bar .options > ul,
#top-bar .options .server-name {
  flex: 0;
}
#top-bar .options > ul :focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
#top-bar .options > ul > li.settings > div.open,
#top-bar .options > ul > li.applications > div.open,
#top-bar .options > ul > li.notifications > div.open {
  opacity: 1;
}
#top-bar .options > ul > li.settings .dropdown a,
#top-bar .options > ul > li.settings .dropdown span {
  white-space: nowrap;
}
#top-bar .options > ul > li.settings .dropdown .user-name {
  cursor: default;
  display: block;
  padding: 3px 20px;
  font-weight: normal;
  line-height: 16px;
  color: #868686;
}
#top-bar .options > ul > li.applications > div.application > a {
  color: inherit;
  font-size: 11pt;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 0;
  white-space: nowrap;
}
#top-bar .options > ul > li.applications > div.application > a:focus,
#top-bar .options > ul > li.applications > div.application > a:hover {
  color: inherit;
  text-decoration: none;
}
#top-bar .options > ul > li.applications > div.application > ul {
  min-width: 100%;
}
.top-bar-label {
  display: block;
  font-weight: normal;
  color: #fff;
  text-transform: uppercase;
  font-family: Helvetica, Tahoma, sans-serif;
  cursor: pointer;
  margin-top: 4px;
  line-height: 12px;
}
.top-bar-label .caret {
  margin-top: 4px;
}
.top-bar-label:hover {
  color: #fff;
}
.dropdown-menu li.create {
  opacity: 0.5;
  margin-top: 2px;
  padding-top: 1px;
  border-top: 1px dashed #ccc;
  font-style: italic;
  position: relative;
}
.dropdown-menu li.create > a {
  padding-right: 30px;
}
.dropdown-menu li.create > a:after {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  content: "\f067";
  position: absolute;
  right: 10px;
}
.dropdown-menu li.create:hover {
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .top-menu li a span {
    display: none;
  }
}
section.primary-content {
  border-top: 1px solid gainsboro;
  padding: 10px;
}
.about {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: inset 4px 4px 8px -6px black;
  flex: 1;
  flex-wrap: wrap;
  overflow: auto;
}
.about .logo {
  margin-right: 20px;
}
.about .logo img {
  display: block;
}
.about .content {
  flex: 1 1 30em;
  min-width: 5em;
  max-width: 50em;
  margin-bottom: 3em;
}
.about .content p {
  padding-bottom: 1em;
}
.about .content .applications {
  width: auto;
}
.about .content .terms {
  display: inline-block;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}
.about hr {
  margin: 0 0 1em;
}
.about .user-status .icon {
  margin-left: 1em;
  opacity: 0.7;
}
.credits {
  padding: 10px 15px 50px;
}
.credits .credit h3 {
  font-size: 1.4em;
  font-weight: normal;
  opacity: 0.8;
  line-height: 2em;
}
.credits .credit aside {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.credits .credit aside span + span {
  margin-top: 0.3em;
}
.credits .credit [data-toggle="collapse"].collapsed .if-not-collapsed {
  display: none;
}
.credits .credit [data-toggle="collapse"]:not(.collapsed) .if-collapsed {
  display: none;
}
.credits .credit + .credit {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed #d8d8d8;
}
.credits .licence {
  margin: 5px 0;
}
.credits .licence pre {
  padding: 10px;
  white-space: pre-wrap;
}
#start-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#start-main .start-panel {
  margin: 0.5em 0;
  width: 404px;
  overflow: hidden;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}
#start-main .start-panel form {
  margin: 10px 60px;
  min-height: 8em;
}
#start-main .start-panel .banner {
  max-width: 404px;
  display: block;
  margin: 0 auto;
}
#start-main .start-panel fieldset legend,
#start-main .start-panel .form-tabs {
  display: none;
}
#start-main .start-panel .form-group {
  margin-bottom: 1em;
}
#start-main .start-panel .nav-links {
  text-align: right;
  padding: 10px 20px;
}
#start-main .start-panel .nav-links > li {
  display: inline-block;
}
#start-main .start-panel .nav-links > li:not(:last-child):after {
  content: " |";
}
#start-main .start-panel .auth-provider-form {
  text-align: center;
  background: #F7F7F7;
  border-top: 1px solid #ccc;
  padding: 1em 0 1em;
  margin: 0;
}
#start-main .start-panel .auth-provider-form button.auth-provider img {
  margin-right: 0.3em;
}
#start-main .start-panel > .start-content > .alert {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
#start-main .start-panel > .start-content .alert {
  margin-bottom: 12px;
}
#start-main .start-panel > .start-content .form header.main,
#start-main .start-panel > .start-content .form section.main,
#start-main .start-panel > .start-content .form footer.main {
  padding: 0;
  border: none;
  background: none;
}
#start-main .start-panel > .start-content .form header.main {
  color: #000;
  margin-bottom: 1em;
}
#start-main .start-panel > .start-content .form header.main > label {
  padding: 0;
}
#start-main .start-panel > .start-content .form header.main > .controls {
  display: none;
}
#start-main .start-panel > .start-content .form section.main {
  overflow: hidden;
}
#start-main .start-panel > .start-content .form .control-label {
  font-weight: normal;
  white-space: nowrap;
  width: auto;
}
#start-main .start-panel > .start-content .form .control-label > em {
  display: none !important;
}
#start-main .start-panel > .start-content .form .control-label:after {
  content: '';
}
#start-main .start-panel > .start-content .form .help-inline {
  margin-left: -8px;
}
#start-main .start-panel > .login-content .message {
  margin-bottom: 5px;
  background-color: #ffc;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
}
#start-main .start-panel #announcements {
  margin: 0.3em 0.5em;
}
#start-main .start-panel #announcements > section {
  padding: 0.1em;
  max-height: 300px;
  overflow-y: auto;
}
#start-main .start-panel #announcements > section > .announcement {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0.5em;
  background: #fefefe;
  margin-bottom: 0.5em;
}
#start-main .start-panel #announcements > section > .announcement > .header {
  display: flex;
  flex-direction: row;
  padding-bottom: 0.2em;
  border-bottom: 1px dotted #eee;
}
#start-main .start-panel #announcements > section > .announcement > .header > .title {
  flex-grow: 1;
  font-weight: bold;
}
#start-main .start-panel #announcements > section > .announcement > .header > .date {
  flex-shrink: 0;
  justify-content: flex-end;
  font-size: 10px;
  text-align: right;
}
#start-main .start-panel #announcements > section > .announcement > .message {
  margin: 0.2em;
  padding: 0;
}
#start-main .start-panel #announcements > section > .announcement > .message p {
  white-space: pre-wrap;
}
#start-main small {
  font-size: 80%;
}
@media (max-height: 780px) {
  #start-main .start-panel #announcements > section {
    max-height: inherit;
  }
}
@media (min-height: 880px) {
  #start-main {
    justify-content: flex-start;
  }
  #start-main .start-panel {
    margin-top: 101px;
  }
}
@media (max-width: 404px) {
  #start-main {
    justify-content: flex-start;
  }
  #start-main .start-panel {
    width: 100%;
    top: 0;
    border: none;
    border-radius: 0;
    margin: 0;
  }
  #start-main .start-panel .banner {
    max-width: 100%;
  }
  #start-main .start-panel > .start-content form,
  #start-main .start-panel > .login-content form {
    margin: 0.8em 0.16em;
  }
  #start-main .start-panel button {
    width: 100%;
  }
  #start-main .start-panel .nav-links {
    padding: 0;
    text-align: center;
  }
  #start-main .start-panel #announcements > section {
    max-height: inherit;
  }
}
.shortcuts {
  width: 45%;
  margin: 2em;
  border-collapse: collapse;
}
.shortcuts thead th {
  padding-bottom: 8px;
}
.shortcuts tbody {
  margin: 10px;
}
.shortcuts tbody tr {
  border-bottom: solid 1px lightgray;
}
.shortcuts tbody tr:first-child {
  border-top: solid 2px darkgray;
}
.shortcuts tbody tr:last-child {
  border-bottom: none;
}
.shortcuts tbody td {
  padding: 8px;
}
.shortcuts tbody td:first-child {
  width: 16em;
}
.forms-shortcuts {
  float: right;
}
