.iconselect {
	position: relative;

	> .fa {
		font-size: 22px;
		display: inline-block;
		position: absolute;
		padding-left: 4px;
		top: 2px;
	}
}

.form-group.edit {
	.iconselect {
		> .fa {
			right: 46px;
		}
	}
}

.iconselect-result .fa {
	position: relative;
	top: 4px;
	padding-right: 4px;
	font-size: 24px;
	display: inline-block;
}
