@import "~src/style/mixins.less";

.select2-container-multi .select2-choices .select2-search-field input {
	height: auto !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
	margin-top: 4px !important;
	margin-bottom: 4px !important;
}

.select2-results {
	li.select2-result-unselectable > .select2-result-label {
		font-weight: bold;
		cursor: default;
	}

	.select2-result-label > .select2-description {
		font-size: .9em;
		color: #666;
	}

	.select2-highlighted .select2-result-label > .select2-description {
		color: #eee;
	}
}

.select2-container {
	width: 100% !important;
}

.select2-search-choice a {
	.transition-none();
}
