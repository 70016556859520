#main-menu {
  z-index: 2;
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  transition: all 80ms;
}
#main-menu #toggle-menu {
  padding: 7px 8px;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 2px;
  margin: 0 0.4em;
  outline: none;
}
#main-menu .branding {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}
#main-menu .branding .logo {
  overflow: hidden;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
}
#main-menu .branding .logo > img {
  max-height: 50px;
  max-width: 200px;
}
#main-menu .branding .logo:hover,
#main-menu .branding .logo:focus {
  text-decoration: none;
}
#main-menu.with-location section.items > ul > li.location-hidden:not(.recent) {
  display: none;
}
#main-menu .location {
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0.3em 0.3em;
}
#main-menu .location > span {
  flex: 1;
}
#main-menu .location a {
  padding: 0.2em 0.5em;
  border-style: solid;
  border-width: 1px 0;
  transition-duration: 0.15s;
  transition-property: color;
  text-decoration: none;
  cursor: default;
}
#main-menu .location a:first-of-type {
  border-left-width: 1px;
  border-radius: 3px 0 0 3px;
}
#main-menu .location a:last-of-type {
  border-right-width: 1px;
  border-radius: 0 3px 3px 0;
}
#main-menu .search {
  display: flex;
  flex-shrink: 0;
  position: relative;
  padding: 0.7em 0.9em;
  border-bottom: 1px solid transparent;
}
#main-menu .search input {
  border-radius: 4px;
  width: 100%;
  border: 1px solid;
  padding: 0.4em;
  padding-right: 2.5em;
}
#main-menu .search .clear {
  display: none;
  position: absolute;
  right: 1.2em;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 6px;
  padding: 0.1em 0.4em;
  border: none;
  background: none;
  opacity: 0.8;
}
#main-menu .search #toggle-search {
  display: none;
}
#main-menu .empty {
  text-align: center;
  display: none;
  padding: 1em;
}
#main-menu section.items {
  overflow: auto;
}
#main-menu section.items::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#main-menu section.items li .item {
  display: flex;
  padding-right: 1em;
}
#main-menu section.items li .item .icon {
  align-self: center;
  padding-left: 1em;
}
#main-menu section.items li .item .tree {
  display: none;
  align-items: center;
  outline: none;
}
#main-menu section.items li .item .item-label {
  cursor: pointer;
  flex: 1;
  padding: 0.7em;
}
#main-menu section.items li .item .item-label:hover {
  text-decoration: none;
}
#main-menu section.items li .interactive i.glyphicon {
  margin-top: 3px;
}
#main-menu section.items li.parent > .item .tree-expand {
  display: flex;
}
#main-menu section.items li.parent.expanded > .item .tree-expand {
  display: none;
}
#main-menu section.items li.parent.expanded > .item .tree-collapse {
  display: flex;
}
#main-menu section.items li.parent.expanded > ul {
  display: block;
}
#main-menu section.items ul.level-1::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#main-menu section.items ul.level-1 > li > .item .item-label {
  font-size: 1.25em;
}
#main-menu section.items ul.level-1 > li ul {
  display: none;
}
#main-menu section.items ul.level-1 .recent {
  border-bottom: 2px groove transparent;
}
#main-menu section.items ul.level-1 .recent .icon,
#main-menu section.items ul.level-1 .recent .tree {
  padding-left: 1em;
  opacity: 0.5;
}
#main-menu section.items ul.level-1 .recent > .item .item-label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  padding-left: 0.4em;
}
#main-menu section.items ul.level-1 .recent ul {
  padding-bottom: 1em;
}
#main-menu section.items ul.level-1 .recent ul .item-label {
  padding: 0.5em;
  font-size: 1em !important;
}
#main-menu section.items ul.level-1 .recent .customize {
  display: none;
}
#main-menu section.items ul.level-2::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#main-menu section.items ul.level-2 > li > .item .icon {
  padding-left: 2em;
}
#main-menu section.items ul.level-2 > li > .item .item-label {
  font-size: 1.2em;
}
#main-menu section.items ul.level-3 .item .icon {
  padding-left: 3em;
}
#main-menu section.items ul.level-3 .item .item-label {
  font-size: 1em;
}
#main-menu section.items ul.level-4 .item .icon {
  padding-left: 4em;
}
#main-menu section.items ul.level-5 .item .icon {
  padding-left: 5em;
}
#main-menu .customize {
  display: flex;
  align-items: center;
  padding-left: 1em;
}
#main-menu .customize .fa {
  margin: 0.3em;
  padding: 6px;
  border-radius: 3px;
  text-decoration: none;
  opacity: 0.7;
}
#main-menu .customize .fa:hover {
  opacity: 1;
}
#main-menu .create-menu {
  padding: 1em;
  justify-content: flex-end;
}
#main-menu.filtered .search .clear {
  display: block;
}
#main-menu.filtered section.items > ul li {
  display: none;
}
#main-menu.filtered section.items > ul li .item {
  opacity: 0.3;
}
#main-menu.filtered section.items .filter-visible {
  display: block;
}
#main-menu.filtered section.items .filter-visible.parent > .item .tree-expand {
  display: flex;
}
#main-menu.filtered section.items .filter-visible.parent > .item .tree-collapse {
  display: none;
}
#main-menu.filtered section.items .filter-visible.parent.filter-expanded > .item .tree-expand {
  display: none;
}
#main-menu.filtered section.items .filter-visible.parent.filter-expanded > .item .tree-collapse {
  display: flex;
}
#main-menu.filtered section.items .filter-visible.parent.filter-expanded > ul {
  display: block;
}
#main-menu.filtered section.items .filter-hit > .item {
  opacity: 1;
  border-left-width: 0.5em;
  border-left-style: solid;
}
#main-menu.filtered section.items li.expanded > .item .tree-collapse {
  display: none;
}
#main-menu.filtered section.items li.expanded > ul {
  display: none;
}
#main-menu.filtered.without-matches .empty {
  display: block;
}
#main-menu.collapsed {
  margin-left: -220px;
}
#main-menu.collapsed > header {
  justify-content: flex-end;
}
#main-menu.collapsed > header .logo {
  display: none;
}
#main-menu.collapsed > header #toggle-menu {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  opacity: 0.7;
  cursor: pointer;
}
#main-menu.collapsed > header #toggle-menu:hover {
  opacity: 1;
}
#main-menu.collapsed section.search.flyout {
  padding: 0.5em;
}
#main-menu.collapsed section.search.flyout .clear {
  display: block;
}
#main-menu.collapsed section.search:not(.flyout) {
  box-shadow: none;
  justify-content: flex-end;
  padding: 0;
}
#main-menu.collapsed section.search:not(.flyout) input,
#main-menu.collapsed section.search:not(.flyout) .clear {
  display: none;
}
#main-menu.collapsed section.search:not(.flyout) #toggle-search {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  opacity: 0.7;
  cursor: pointer;
}
#main-menu.collapsed section.search:not(.flyout) #toggle-search:hover {
  opacity: 1;
}
#main-menu.collapsed section.items {
  margin-top: 0.5em;
}
#main-menu.collapsed section.items ul.level-1 .recent {
  border-bottom: 0;
}
#main-menu.collapsed section.items > ul:not(.flyout-list) > li > .item {
  padding: 0;
  justify-content: flex-end;
}
#main-menu.collapsed section.items > ul:not(.flyout-list) > li > .item .icon {
  font-size: 1.2em;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  opacity: 0.7;
  cursor: pointer;
}
#main-menu.collapsed section.items > ul:not(.flyout-list) > li > .item .icon:hover {
  opacity: 1;
}
#main-menu.collapsed section.items > ul:not(.flyout-list) > li > .item .item-label,
#main-menu.collapsed section.items > ul:not(.flyout-list) > li > .item .tree {
  display: none;
}
#main-menu.collapsed section.items > ul:not(.flyout-list) > li.parent.expanded > .item .tree-collapse {
  display: none;
}
#main-menu.collapsed section.items > ul:not(.flyout-list) > li.parent.expanded > ul {
  display: none;
}
#main-menu.collapsed section.items::-webkit-scrollbar {
  width: 5px;
}
#main-menu.collapsed .flyout {
  z-index: 2;
  width: 260px;
  position: absolute;
}
#main-menu.collapsed .flyout.flyout-menu {
  left: 40px;
}
#main-menu.collapsed .flyout.flyout-drawer {
  left: 0;
}
#main-menu.collapsed .flyout-header {
  display: flex !important;
  height: 40px;
  align-items: center;
  padding: 0 1em;
}
#main-menu.collapsed .flyout-list {
  display: block !important;
  overflow: auto;
}
#main-menu.collapsed .active-flyout > .item .icon {
  opacity: 1 !important;
}
#main-menu.collapsed .active-flyout > .item:hover {
  background: none;
}
#main-menu.collapsed .customize {
  display: none;
}
#main-menu.collapsed section.empty {
  z-index: 3;
  margin-top: 40px;
  margin-left: 220px;
  width: 260px;
}
#main-menu.light {
  background-color: #fdfcfc;
  box-shadow: 1px 0px 7px 0px rgba(0, 0, 0, 0.48);
}
#main-menu.light a {
  color: #3e3e3e;
}
#main-menu.light #toggle-menu {
  color: #505050;
}
#main-menu.light #toggle-menu.active {
  color: #888787;
  background-color: #e9eaec;
  box-shadow: inset 0 0 3px 0px rgba(0, 0, 0, 0.54);
  border: 1px solid #ffffff;
}
#main-menu.light .location {
  border-color: rgba(0, 0, 0, 0.27);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.34);
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 0 7px -4px black;
}
#main-menu.light .location a {
  border-color: #757575;
  background-color: #cfd3da;
}
#main-menu.light .location a:hover {
  background-color: #dadde2;
}
#main-menu.light .location a.active {
  background-color: #0394dd;
  color: #fff;
}
#main-menu.light .search {
  background-color: #fdfcfc;
  border-top: 1px solid rgba(0, 0, 0, 0.28);
  border-bottom: 1px solid #d2d2d2;
}
#main-menu.light .search input {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: #c3c3c3;
}
#main-menu.light .fa {
  color: #505050;
}
#main-menu.light .empty {
  color: rgba(0, 0, 0, 0.36);
}
#main-menu.light section.items::-webkit-scrollbar-track {
  background: #e6e6e6;
}
#main-menu.light section.items::-webkit-scrollbar-thumb {
  background: #d4d4d4;
}
#main-menu.light section.items::-webkit-scrollbar-thumb:window-inactive {
  background: #d4d4d4;
}
#main-menu.light section.items::-webkit-scrollbar-corner {
  background: #e6e6e6;
}
#main-menu.light section.items ul.level-1::-webkit-scrollbar-track {
  background: #e6e6e6;
}
#main-menu.light section.items ul.level-1::-webkit-scrollbar-thumb {
  background: #d4d4d4;
}
#main-menu.light section.items ul.level-1::-webkit-scrollbar-thumb:window-inactive {
  background: #d4d4d4;
}
#main-menu.light section.items ul.level-1::-webkit-scrollbar-corner {
  background: #e6e6e6;
}
#main-menu.light section.items ul.level-2::-webkit-scrollbar-track {
  background: #e6e6e6;
}
#main-menu.light section.items ul.level-2::-webkit-scrollbar-thumb {
  background: #d4d4d4;
}
#main-menu.light section.items ul.level-2::-webkit-scrollbar-thumb:window-inactive {
  background: #d4d4d4;
}
#main-menu.light section.items ul.level-2::-webkit-scrollbar-corner {
  background: #e6e6e6;
}
#main-menu.light section.items li.recent > .item .item-label {
  color: rgba(0, 0, 0, 0.4);
}
#main-menu.light section.items li:not(.recent):not(.active-flyout) .item:hover {
  background-color: rgba(255, 194, 107, 0.09);
}
#main-menu.light section.items li:not(.recent).expanded.active:not(.parent):not(.filter-hit) {
  background-color: #a3e0ff;
}
#main-menu.light .customize .fa {
  color: white;
  opacity: 0.9;
}
#main-menu.light .customize .edit {
  background-color: #f0ad4e;
}
#main-menu.light .customize .add {
  background-color: #5cb85c;
}
#main-menu.light.collapsed .flyout-header {
  border: 1px solid #ccc;
  background: #fff9e5;
}
#main-menu.light.collapsed .flyout-header:not(.search) {
  border-left: 1px dashed rgba(130, 130, 130, 0.3);
}
#main-menu.light.collapsed .flyout-header.search {
  z-index: 3;
  box-shadow: -1px 3px 6px -3px rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid #b7b7b7;
}
#main-menu.light.collapsed .flyout-header.search input {
  outline-color: #ffdc8e;
}
#main-menu.light.collapsed .flyout-list {
  background-color: white;
  border: 1px solid #bababa;
  border-top: none;
}
#main-menu.light.collapsed .flyout-list.flyout-menu {
  box-shadow: 0px 4px 17px -2px rgba(0, 0, 0, 0.45);
}
#main-menu.light.collapsed .flyout-list.flyout-drawer {
  box-shadow: 4px 8px 17px 0px rgba(0, 0, 0, 0.3);
}
#main-menu.light.collapsed .active-flyout {
  background: #fff9e5;
  box-shadow: inset 10px 0px 4px 0px rgba(0, 0, 0, 0.49);
}
#main-menu.light.collapsed .active-flyout.recent .flyout-header {
  border-left: none;
}
#main-menu.light.collapsed .active-flyout:hover {
  background: #fff9e5;
}
#main-menu.light:not(.filtered) section.items li:not(.recent).expanded.parent ul:not(.flyout-list) {
  background-color: rgba(127, 127, 127, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: inset 0px 3px 9px -7px black;
}
#main-menu.light.filtered .filter-hit {
  background-color: #fff6d9;
}
#main-menu.light.filtered .filter-hit > .item {
  border-left-color: #efa53e;
}
#main-menu.light.filtered.without-matches .search {
  background-color: #eddede;
}
#main-menu.light.filtered.without-matches .search input {
  outline-color: #f9bebe;
}
#main-menu.dark {
  background-color: hsl(224, 3%, 28%);
}
#main-menu.dark #toggle-menu {
  color: white;
}
#main-menu.dark #toggle-menu:hover {
  -webkit-box-shadow: 0px 0px 30px 0px hsla(0, 0%, 100%, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px hsla(0, 0%, 100%, 0.2);
  box-shadow: 0px 0px 30px 0px hsla(0, 0%, 100%, 0.2);
}
#main-menu.dark #toggle-menu.active {
  background-color: hsl(223, 3%, 50%);
}
#main-menu.dark .location {
  border-color: #2f2f2f;
  color: hsla(0, 0%, 100%, 0.25);
}
#main-menu.dark .location a {
  border-color: hsl(0, 0%, 20%);
  color: hsl(0, 0%, 80%);
  background-color: hsl(227, 3%, 34%);
}
#main-menu.dark .location a:hover {
  background-color: #b4b4b4;
  color: hsl(0, 0%, 100%);
}
#main-menu.dark .location a.active {
  background-color: hsl(200, 97%, 44%);
  color: hsl(0, 0%, 100%);
}
#main-menu.dark .search {
  background-color: hsla(0, 0%, 100%, 0.05);
  border-top: 1px solid hsla(0, 0%, 100%, 0.14);
  border-bottom: 2px groove hsla(0, 0%, 48%, 0.4);
}
#main-menu.dark .search input {
  background-color: hsla(0, 0%, 21%, 0.46);
  color: white;
  border-color: hsl(0, 0%, 12%);
}
#main-menu.dark .fa {
  color: white;
}
#main-menu.dark .empty {
  color: hsla(0, 0%, 100%, 0.48);
}
#main-menu.dark section.items::-webkit-scrollbar-track {
  background: hsl(226, 3%, 28%);
}
#main-menu.dark section.items::-webkit-scrollbar-thumb {
  background: hsl(223, 3%, 50%);
}
#main-menu.dark section.items::-webkit-scrollbar-thumb:window-inactive {
  background: hsl(223, 3%, 50%);
}
#main-menu.dark section.items::-webkit-scrollbar-corner {
  background: hsl(224, 3%, 28%);
}
#main-menu.dark section.items ul.level-1::-webkit-scrollbar-track {
  background: hsl(226, 3%, 28%);
}
#main-menu.dark section.items ul.level-1::-webkit-scrollbar-thumb {
  background: hsl(223, 3%, 50%);
}
#main-menu.dark section.items ul.level-1::-webkit-scrollbar-thumb:window-inactive {
  background: hsl(223, 3%, 50%);
}
#main-menu.dark section.items ul.level-1::-webkit-scrollbar-corner {
  background: hsl(224, 3%, 28%);
}
#main-menu.dark section.items ul.level-2::-webkit-scrollbar-track {
  background: hsl(226, 3%, 28%);
}
#main-menu.dark section.items ul.level-2::-webkit-scrollbar-thumb {
  background: hsl(223, 3%, 50%);
}
#main-menu.dark section.items ul.level-2::-webkit-scrollbar-thumb:window-inactive {
  background: hsl(223, 3%, 50%);
}
#main-menu.dark section.items ul.level-2::-webkit-scrollbar-corner {
  background: hsl(224, 3%, 28%);
}
#main-menu.dark section.items li .item .item-label {
  color: hsla(0, 0%, 100%, 0.87);
}
#main-menu.dark section.items li.recent > .item .item-label {
  color: hsla(0, 0%, 100%, 0.5);
}
#main-menu.dark section.items li:not(.recent):not(.active-flyout) .item:hover {
  background-color: hsla(0, 0%, 100%, 0.03);
}
#main-menu.dark section.items li:not(.recent).expanded.active:not(.parent):not(.filter-hit) {
  background-color: hsl(200, 97%, 45%);
}
#main-menu.dark .customize .edit {
  background-color: hsla(46, 100%, 50%, 0.39);
}
#main-menu.dark .customize .add {
  background-color: hsla(121, 100%, 50%, 0.39);
}
#main-menu.dark.collapsed .flyout-header {
  background: hsl(227, 3%, 38%);
}
#main-menu.dark.collapsed .flyout-list {
  background: hsl(222, 3%, 29%);
}
#main-menu.dark.collapsed .flyout-list.flyout-menu {
  box-shadow: 0px 5px 15px 0px hsla(0, 0%, 0%, 0.54);
}
#main-menu.dark.collapsed .flyout-list.flyout-drawer {
  box-shadow: 0px 10px 30px black;
}
#main-menu.dark.collapsed .active-flyout {
  background: hsl(227, 3%, 38%);
  box-shadow: inset 5px 1px 2px 0px hsla(0, 0%, 0%, 0.58);
}
#main-menu.dark.collapsed .active-flyout.recent .flyout-list {
  background: hsl(227, 3%, 38%);
  border-top: 2px groove hsla(0, 0%, 48%, 0.4);
}
#main-menu.dark.collapsed .active-flyout:hover {
  background: hsl(227, 3%, 38%);
}
#main-menu.dark:not(.filtered) section.items li:not(.recent).expanded.parent ul:not(.flyout-list) {
  background-color: hsla(0, 0%, 0%, 0.12);
}
#main-menu.dark.filtered .filter-hit {
  background-color: hsl(219, 3%, 19%);
}
#main-menu.dark.filtered .filter-hit > .item {
  border-left-color: hsl(39, 84%, 41%);
}
#main-menu.dark.filtered.without-matches .search {
  background-color: hsl(0, 16%, 46%);
}
#main-menu.dark.filtered.without-matches .search input {
  outline-color: hsl(0, 39%, 32%);
}
